/* libs/cloud/shared/ui/components/src/lib/my-account-side-nav/my-account-side-nav.component.scss */
:host #sh-my-account-nav-logo-div {
  display: flex;
}
:host #sh-my-account-nav-logo-div #sh-my-account-nav-logo-button {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44px !important;
  gap: 10px;
  border-radius: 0px !important;
}
:host #sh-my-account-nav-logo-div #sh-my-account-nav-logo-button #sh-my-account-nav-logo-img {
  margin-right: 4px;
  height: 22px;
  margin-left: 16px;
}
:host #sh-my-account-nav-logo-div #sh-my-account-nav-logo-button #sh-my-account-nav-logo-span {
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  color: white;
  letter-spacing: 0.007em;
}
:host #sh-my-account-nav-title-span {
  margin-left: 16px;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.005em;
  margin-right: 16px;
}
:host #sh-my-account-nav-div {
  margin-top: 10px;
  margin-bottom: 8px;
}
:host mat-nav-list {
  margin-left: 16px;
  margin-right: 16px;
}
:host mat-nav-list ::ng-deep .mdc-list-item__primary-text {
  white-space: pre-wrap !important;
}
:host mat-nav-list ::ng-deep .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}
:host mat-nav-list mat-list-item {
  border-radius: 8px !important;
  width: 180px;
  margin-bottom: 8px;
  height: auto;
  min-height: 34px;
  padding: 8px 16px;
}
:host mat-nav-list mat-list-item .marketing-icon {
  background: url(/assets/images/marketing-icon.svg) no-repeat;
  width: 20px;
  height: 16px;
  margin-right: 10px;
}
:host mat-nav-list mat-list-item.mat-menu-item-active .marketing-icon {
  background: url(/assets/images/marketing-icon-active.svg) no-repeat;
}
:host mat-nav-list .mat-list-text {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.008em;
}
:host mat-nav-list prism-icon {
  margin-right: 5px;
}
:host mat-nav-list .mat-icon {
  margin-right: 6px;
}
:host .mat-menu-item-active {
  background-color: #b2ff33 !important;
}
:host .mat-menu-item-active prism-icon,
:host .mat-menu-item-active .mat-icon,
:host .mat-menu-item-active .mat-list-text {
  color: #222222;
}
:host ::ng-deep .mdc-button__label {
  display: flex;
}
/*# sourceMappingURL=my-account-side-nav.component.css.map */
