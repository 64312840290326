/* libs/cloud/shared/ui/components/src/lib/legacy-layout/empty-state/empty-state.component.scss */
:host {
  height: 100%;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  flex: 1 1 0%;
  min-height: 100%;
  text-align: center;
}
:host .empty-state > div {
  text-align: center;
}
:host .empty-state > img {
  max-width: 366px;
}
@media only screen and (max-width: 359px) {
  :host .empty-state {
    width: 144px;
  }
  :host .empty-state > img {
    max-width: 144px;
  }
}
@media only screen and (max-width: 600px) {
  :host .empty-state {
    width: 240px;
  }
  :host .empty-state > div {
    text-align: left;
  }
  :host .empty-state > img {
    max-width: 240px;
  }
}
/*# sourceMappingURL=empty-state.component.css.map */
