export interface Maintenance {
	maintenance: boolean;
	banner: boolean;
	startAt: string;
	endAt: string;
	message: string;
}

export interface OrgData {
	isOrgSelected: boolean;
	orgName: string;
	orgId: string;
	orgNumber?: string;
}
export interface OrganizationsDto {
	description: string;
	name: string;
	number: string;
}
export interface OrganizationsResponse {
	createdOn: string;
	id: string;
	orgId: string;
	data: OrganizationsDto;
	status: string;
	default?: boolean;
	invitationToken: string;
}

export enum OrganizationInviteStatus {
	InviteSent = 'INVITE_SENT',
	InviteAccepted = 'INVITE_ACCEPTED',
	InviteRejected = 'INVITE_REJECTED',
	InviteRevoked = 'INVITE_REVOKED',
	UserExpired = 'USER_EXPIRED'
}

export interface DefaultOrgData {
	orgName: string;
	orgId: string;
}
export enum VerificationStatus {
	VERIFIED = 'VERIFIED',
	REQUESTED = 'REQUESTED',
	NONVERIFIED = 'NON-VERIFIED'
}

export interface AccountInformationDto {
	companyName?: string;
	department?: string;
	email?: string;
	emailPreferences?: {
		louderMusician?: boolean;
		louderContentCreator?: boolean;
		trainingCommunication?: boolean;
		marketingCommunication?: boolean;
	};
	firstName?: string;
	interests?: Array<string>;
	jobTitle?: string;
	lastName?: string;
	mobilePhone?: string;
	optOutOfEmail?: boolean;
	organizationDetails?: OrganizationDetails;
	preferences?: {
		language?: string;
		dateFormat?: string;
		region?: string;
		timeZone?: string;
		orderOfNames?: Array<string>;
	};
	profileImage?: {
		upload?: string;
		get?: {
			original?: string;
		};
	};
	relationToShure?: Array<string>;
	segment?: string;
	segmentDetails?: string;
	tc?: {
		isTcAccepted?: boolean;
		tcAcceptedAt?: string;
	};
	workPhone?: string;
}

export interface OrganizationDetails {
	accountNumber?: string;
	companyName?: string;
	organizationName?: string;
	verificationStatus?: 'VERIFIED' | 'REQUESTED' | 'NON-VERIFIED';
	verifiedAt?: string;
}
