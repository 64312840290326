export enum ChannelLogicInputDeviceType {
	Ani4In = 'ANI4IN',
	Ani22 = 'ANI22',
	Mxa310 = 'MXA310',
	Mxa7102Ft = 'MXA710_2FT',
	Mxa7102FtImx = 'MXA710_2FT_IMX',
	Mxa7104Ft = 'MXA710_4FT',
	Mxa7104FtImx = 'MXA710_4FT_IMX',
	Mxa901RoundImx = 'MXA901_ROUND_IMX',
	Mxa902Imx = 'MXA902_IMX',
	Mxa910 = 'MXA910',
	Mxa910Imx = 'MXA910_IMX',
	Mxa920 = 'MXA920',
	Mxa920Imx = 'MXA920_IMX',
	Mxa920Round = 'MXA920_ROUND',
	Mxa920RoundImx = 'MXA920_ROUND_IMX',
	Unknown = 'UNKNOWN'
}
