/* libs/cloud/shared/ui/components/src/lib/ag-grid-components/cell-renderers/info-icon-renderer/info-icon-renderer.component.scss */
:host #sh-info-icon-main {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 20px;
  flex-shrink: 0;
}
:host #sh-info-icon-container {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-right: 2px;
  justify-content: center;
  gap: 10px;
}
:host #sh-info-icon-info-mat-icon {
  display: flex;
}
:host ::ng-deep #sh-info-icon-div,
:host ::ng-deep #sh-info-icon-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::ng-deep .sh-info-icon-status-tooltip .mdc-tooltip__surface {
  max-width: 480px !important;
}
/*# sourceMappingURL=info-icon-renderer.component.css.map */
