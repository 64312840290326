<mat-toolbar>
	<div class="breadcrumb-container">
		<ng-container *ngFor="let crumb of breadcrumbs; let i = index; trackBy: trackByFn">
			<!-- hidefirstcrumb class used to hide first breadscrumb in mobile portrait view -->
			<a
				class="mat-caption breadcrumb"
				(click)="!(disableLast && i === breadcrumbs.length - 1) && onBreadcrumbClick(crumb)"
				[class.lastCrumb]="disableLast && i === breadcrumbs.length - 1"
				[class.hidefirstcrumb]="breadcrumbs.length > 2 && i === 0"
			>
				{{ crumb.name }}
			</a>
			<mat-icon *ngIf="i !== breadcrumbs.length - 1" fontSet="prism-mat" fontIcon="keyboard_arrow_right">
			</mat-icon>
		</ng-container>
	</div>
</mat-toolbar>
