import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@jsverse/transloco';

import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
	imports: [CommonModule, MatFormFieldModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, TranslocoModule],
	declarations: [LanguageSelectorComponent],
	exports: [LanguageSelectorComponent]
})
export class LanguageSelectorModule {}
