/* libs/cloud/shared/ui/components/src/lib/device-state-bar/device-state-bar.component.scss */
:host .sh-device-state {
  box-sizing: border-box;
  min-width: 11px;
  border-width: 1px;
  min-height: 5px;
  width: 100%;
  height: 100%;
}
:host .sh-device-good {
  border: 0.115625rem solid var(--prism-color-theme-state-good-active);
  background-color: var(--prism-color-theme-state-good-active);
}
:host .sh-device-ok {
  border: 0.115625rem solid var(--prism-color-theme-state-ok);
  background-image:
    linear-gradient(
      -45deg,
      var(--prism-color-theme-state-ok) 0 calc(50% - 0.140625rem),
      transparent calc(50% - 0.140625rem) calc(50% - 0.046875rem),
      var(--prism-color-theme-state-ok) calc(50% - 0.046875rem) calc(50% + 0.046875rem),
      transparent calc(50% + 0.046875rem) calc(50% + 0.140625rem),
      var(--prism-color-theme-state-ok) calc(50% + 0.140625rem) 100%);
}
:host .sh-device-bad {
  border: 0.115625rem solid var(--prism-color-theme-state-bad);
  background-image:
    repeating-linear-gradient(
      -45deg,
      transparent 0 0.09375rem,
      var(--prism-color-theme-state-bad) 0.09375rem 0.234375rem);
}
:host .sh-device-inactive {
  background-color: var(--prism-color-theme-disabled);
}
:host .sh-device-unknown {
  font-size: 14px;
  border: 1px solid var(--prism-color-theme-neutral-pop);
  text-align: center;
  color: var(--prism-color-theme-neutral-pop);
}
:host .sh-device-unknown::before {
  content: "?";
}
/*# sourceMappingURL=device-state-bar.component.css.map */
