export enum LightBehavior {
	On = 'ON',
	Flashing = 'FLASHING',
	Off = 'OFF'
}

export enum LightingRingStyle {
	Segment = 'SEGMENT',
	Ring = 'RING'
}

export enum LightingState {
	Muted = 'MUTED',
	Unmuted = 'UNMUTED'
}

export enum GateOffBehavior {
	Off = 'OFF',
	FollowMuteColor = 'FOLLOW_MUTE_COLOR'
}

export enum LightColor {
	Blue = 'BLUE',
	Cyan = 'CYAN',
	Gold = 'GOLD',
	Green = 'GREEN',
	LightPurple = 'LIGHT_PURPLE',
	Orange = 'ORANGE',
	Orchid = 'ORCHID',
	Pink = 'PINK',
	PowderBlue = 'POWDER_BLUE',
	Purple = 'PURPLE',
	Red = 'RED',
	SkyBlue = 'SKY_BLUE',
	Turquoise = 'TURQUOISE',
	Violet = 'VIOLET',
	White = 'WHITE',
	Yellow = 'YELLOW',
	YellowGreen = 'YELLOW_GREEN'
}

export const colorToHex: Record<string, string> = {
	[LightColor.Red]: '#FF482A',
	[LightColor.Orange]: '#FF9630',
	[LightColor.Gold]: '#FFD700',
	[LightColor.Yellow]: '#FFF32C',
	[LightColor.YellowGreen]: '#D8FF00',
	[LightColor.Green]: '#3CFC22',
	[LightColor.Turquoise]: '#40e0d0',
	[LightColor.PowderBlue]: '#B0E0E6',
	[LightColor.Cyan]: '#00FFFF',
	[LightColor.SkyBlue]: '#00BFFF',
	[LightColor.Blue]: '#266EFF',
	[LightColor.Purple]: '#C48FFF',
	[LightColor.LightPurple]: '#D2A5FF',
	[LightColor.Violet]: '#EE82EE',
	[LightColor.Orchid]: '#DA70D6',
	[LightColor.Pink]: '#FF5AB3',
	[LightColor.White]: '#FFFFFF'
};
