/* libs/cloud/shared/ui/components/src/lib/buttons/id-button/id-button.component.scss */
:host {
  display: block;
  height: 100%;
}
:host .sh-id-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
:host .sh-id-button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
:host .sh-id-button-icon.sh-identifying {
  animation: sh-device-identify-pulse 1.1s infinite ease-in-out;
  color: var(--prism-color-theme-accent-primary-contrast);
  background-color: var(--prism-color-theme-brand-ignition-green);
}
@keyframes sh-device-identify-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
/*# sourceMappingURL=id-button.component.css.map */
