import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateFormatterPipe } from './date-formatter.pipe';
import { DefaultValuePipe } from './default-value.pipe';
import { InputDeviceTypePipe } from './input-device-type.pipe';
import { IpDefaultValuePipe } from './ip-default-value.pipe';
import { MacAddressPipe } from './mac-address.pipe';
import { ReplaceIfEmptyOrNullOrUndefined } from './replace-if-empty-null-undefined-pipe.pipe';
import { ThrottleTimePipe } from './throttleTime.pipe';
import { VersionPipe } from './version.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [
		DefaultValuePipe,
		InputDeviceTypePipe,
		IpDefaultValuePipe,
		MacAddressPipe,
		ReplaceIfEmptyOrNullOrUndefined,
		ThrottleTimePipe,
		VersionPipe,
		DateFormatterPipe
	],
	exports: [
		DefaultValuePipe,
		InputDeviceTypePipe,
		IpDefaultValuePipe,
		MacAddressPipe,
		ReplaceIfEmptyOrNullOrUndefined,
		ThrottleTimePipe,
		VersionPipe,
		DateFormatterPipe
	],
	providers: [DatePipe]
})
export class PipesModule {}
