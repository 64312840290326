/* libs/cloud/shared/ui/components/src/lib/header-toolbar/header-toolbar.component.scss */
:host #sh-header-toolbar-feature-button-div {
  margin-right: 20px;
}
:host #sh-header-toolbar-feature-request-button {
  padding: 8px 16px;
  background-color: #404041;
  border-radius: 18px;
}
:host ::ng-deep #sh-header-toolbar-left-content-div .breadcrumb-container {
  color: #fff;
}
:host ::ng-deep #sh-header-toolbar-left-content-div .breadcrumb-container a.breadcrumb:hover:not(.lastCrumb) {
  border-bottom: 2px solid #b2ff33 !important;
}
:host ::ng-deep #sh-header-toolbar-left-content-div .breadcrumb-container a.breadcrumb.lastCrumb {
  opacity: 0.4 !important;
}
:host #sh-header-toolbar-header {
  margin-left: 20px;
}
:host #sh-header-toolbar-secondary-nav-title {
  margin-left: 10px;
  font-weight: 700;
}
:host .mat-toolbar {
  height: 50px;
  padding: 0 10px;
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
}
:host ::ng-deep .mat-mdc-menu-item {
  min-height: 40px !important;
  height: 40px;
}
:host .mat-mdc-menu-item .mdc-list-item__primary-text {
  line-height: 40px;
}
:host ::ng-deep .mdc-menu-surface {
  background-color: #333335;
  color: #fff;
}
:host .sh-header-toolbar-breadcrumb {
  background-color: #000;
}
:host .sh-header-toolbar-breadcrumb-container {
  display: flex;
  height: 50px;
}
:host #sh-header-toolbar-profile-container-div {
  width: 33px;
  height: 33px;
  border: 1px solid #b2ff33;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  cursor: pointer;
}
:host #sh-header-toolbar-profile-container-div[profileColor="0"] {
  background-color: var(--prism-color-theme-brand-green);
}
:host #sh-header-toolbar-profile-container-div[profileColor="1"] {
  background-color: var(--prism-color-theme-brand-orange);
}
:host #sh-header-toolbar-profile-container-div[profileColor="2"] {
  background-color: var(--prism-color-theme-brand-red);
}
:host #sh-header-toolbar-profile-container-div[profileColor="3"] {
  background-color: var(--prism-color-theme-brand-blue);
}
:host #sh-header-toolbar-profile-container-div[profileColor="4"] {
  background-color: var(--prism-color-theme-brand-cyan);
}
:host #sh-header-toolbar-profile-container-div[profileColor="5"] {
  background-color: var(--prism-color-theme-brand-purple);
}
:host #sh-header-toolbar-profile-container-div[profileColor="6"] {
  background-color: var(--prism-color-theme-brand-pink);
}
:host #sh-header-toolbar-profile-img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}
:host #sh-header-toolbar-profile-div,
:host #sh-header-toolbar-profile-char-div {
  width: 50px;
  height: 31px;
  border: 1px solid #222222;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  background-size: cover;
}
:host #sh-header-toolbar-right-content-div {
  display: flex;
}
:host #sh-header-toolbar-left-content-div {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  :host #sh-header-toolbar-menu-button {
    padding: 0px;
    width: 24px;
    height: 24px;
  }
  :host .mat-toolbar {
    padding: 0 16px;
  }
  :host #sh-license-groups-list-container-div {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 480px) {
  :host #sh-header-toolbar-menu-button {
    padding: 0px;
    width: 24px;
    height: 24px;
  }
  :host .mat-toolbar {
    padding: 0 16px;
  }
  :host #sh-header-toolbar-profile-container-div {
    margin-right: 0px;
  }
  :host #sh-header-toolbar-left-content-div {
    max-width: 80%;
  }
}
@media (min-width: 481px) and (max-width: 932px) and (orientation: landscape) {
  :host .mat-toolbar {
    position: relative;
    top: 50px;
    margin-top: -50px;
    padding-right: 6px;
  }
}
/*# sourceMappingURL=header-toolbar.component.css.map */
