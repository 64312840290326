import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { OktaInterfaceService } from './okta-interface.service';

@NgModule({})
export class OktaModule {
	public static forRoot(oktaAuth: OktaAuth, signinRedirectRoute = '/signin'): ModuleWithProviders<OktaModule> {
		return {
			ngModule: OktaModule,
			providers: [
				OktaInterfaceService,
				{
					provide: OKTA_CONFIG,
					useValue: {
						oktaAuth,
						onAuthRequired: (oktaAuth: OktaAuth, injector: Injector): void => {
							//
							// If there's no previous login state, it means the app is being launched.
							// If that's the case, or there isn't a a postLogoutRedirect URI configured
							// for the app, we just want to route to the signingRedirectRoute value.
							//
							// The else clause handles the case when we need to redirct an application
							// back to a parent site, such as ignite, when the user signs out of an application.
							//
							if (
								!oktaAuth.authStateManager.getPreviousAuthState() ||
								!oktaAuth.options.postLogoutRedirectUri
							) {
								const router = injector.get(Router);
								router.navigate([signinRedirectRoute]);
							} else {
								window.location.href = oktaAuth.options.postLogoutRedirectUri;
							}
						}
					}
				}
			]
		};
	}
}
