import { Component, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'sh-content-loader',
	templateUrl: './content-loader.component.html',
	styleUrls: ['./content-loader.component.scss']
})
export class ContentLoaderComponent {
	@HostBinding('class.sh-loader-background') public loaderBackground = true;
	@HostBinding('class.sh-loader-card') public loaderCard = false;

	@Input() public set background(bg: 'card' | 'background') {
		switch (bg) {
			case 'card':
				this.loaderBackground = false;
				this.loaderCard = true;
				break;
			case 'background':
				this.loaderBackground = true;
				this.loaderCard = false;
				break;
		}
	}
}
