import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

import { BreadcrumbsModule } from '../breadcrumbs';
import { ShIgniteDialogBoxService } from '../dialogs/dialog-box-ignite/dialog-box.service';

import { HeaderToolbarComponent } from './header-toolbar.component';

@NgModule({
	declarations: [HeaderToolbarComponent],
	exports: [HeaderToolbarComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		BreadcrumbsModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		RouterModule,
		TranslocoModule
	],
	providers: [ShIgniteDialogBoxService]
})
export class HeaderToolbarModule {}
