/* libs/cloud/shared/ui/components/src/lib/search-bar/search-bar.component.scss */
:host {
  height: 32px;
  min-width: 220px;
  position: relative;
}
:host .sh-search-container {
  border-radius: 20px;
  height: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
}
:host .sh-search-input {
  border: none;
  outline: 0;
  width: 100%;
}
:host .sh-search-button-arrow .mat-icon {
  font-size: 18px;
  margin-top: 2px;
}
:host .mat-icon {
  font-size: 24px;
  margin-left: 5px;
}
/*# sourceMappingURL=search-bar.component.css.map */
