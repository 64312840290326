/* libs/shared/angular/ui/components/device-image/src/lib/device-image.component.scss */
:host-context([theme=dark]) .sh-device-image.sh-virtual-device {
  filter: invert(1);
}
:host {
  --prism-status-indicator-size: 10px;
  width: 30px;
  height: 30px;
}
:host .sh-device-image {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  display: grid;
  align-items: center;
  justify-items: center;
}
:host .sh-offline-device {
  opacity: 0.3;
}
:host .sh-lock-icon {
  position: sticky;
  float: right;
  width: 14px;
  margin-top: -29px;
  margin-right: -4px;
}
:host .sh-link-icon {
  position: sticky;
  float: right;
  width: 16px;
  margin-top: -16px;
  margin-right: -2px;
}
:host .sh-firmware-status {
  position: sticky;
  float: right;
  margin-right: -5px;
  margin-top: -18px;
}
/*# sourceMappingURL=device-image.component.css.map */
