import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
	selector: 'sh-cookie-dialog',
	templateUrl: './cookie-dialog.component.html',
	styleUrls: ['./cookie-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, MatDialogModule, TranslocoModule]
})
export class CookieDialogComponent {}
