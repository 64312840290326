import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
/**
 * validates the illegalCharacters
 * @param value string that represents value
 * @returns
 */
export function illegalCharactersValidator(value: string): boolean {
	const illegalCharactersRegex = /[\\/:*?"<>|[\]#%`{}+^&$@=;]/;
	const hasIllegalCharacters: boolean = illegalCharactersRegex.test(value);
	return hasIllegalCharacters;
}
/**
 * validates the reservedNames
 * @param value string that represents value
 * @returns true if reservedName includes FileName
 */
export function reservedNamesValidator(value: string): boolean {
	const fileName = value.toLowerCase();
	const reservedNames: string[] = [
		'com1',
		'com2',
		'com3',
		'com4',
		'com5',
		'com6',
		'com7',
		'com8',
		'com9',
		'lpt1',
		'lpt2',
		'lpt3',
		'lpt4',
		'lpt5',
		'lpt6',
		'lpt7',
		'lpt8',
		'lpt9',
		'con',
		'nul',
		'prn'
	];

	const asciiReservedNameRegex = /^0x((7f)|([0-18-9a-f][0-9a-f]))$/;
	const containsAsciiReservedName: boolean = asciiReservedNameRegex.test(fileName);

	if (reservedNames.includes(fileName) || containsAsciiReservedName) {
		return true;
	}
	return false;
}
/**
 * validates the illegalCharacters in the Form
 */
export function illegalCharactersFormValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const value: string = control.value;
		const hasIllegalCharacters: boolean = illegalCharactersValidator(value);
		return hasIllegalCharacters ? { illegalCharacters: true } : null;
	};
}
/**
 * validates the reservedNames in the forms
 * @returns ture if containsReservedName
 */
export function reservedNamesFormValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const value: string = control.value;
		const containsReservedName: boolean = reservedNamesValidator(value);

		if (containsReservedName) {
			return { reservedName: true };
		}

		return null;
	};
}

export function noWhitespaceValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { whitespace: true };
	};
}
