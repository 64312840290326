/* libs/cloud/shared/ui/components/src/lib/legacy-layout/stacked-layout/stacked-layout.component.scss */
:host {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host .sh-stacked-layout-container {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  overflow: hidden;
}
/*# sourceMappingURL=stacked-layout.component.css.map */
