import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { VisibilityToggleButtonComponent } from './visibility-toggle-button.component';

@NgModule({
	declarations: [VisibilityToggleButtonComponent],
	exports: [VisibilityToggleButtonComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class VisibilityToggleButtonModule {}
