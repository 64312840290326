/* libs/cloud/shared/ui/components/src/lib/buttons/zoom-controls-buttons/zoom-controls-buttons.component.scss */
:host {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 12px;
}
:host button {
  width: 48px;
  min-width: 48px;
  padding: 0;
}
:host #sh-zoom-control-fit {
  margin-right: 20px;
}
/*# sourceMappingURL=zoom-controls-buttons.component.css.map */
