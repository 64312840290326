import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

export enum DeviceCategory {
	Microphone = 'microphone',
	Speaker = 'speaker',
	Processor = 'processor',
	Coverage = 'coverage',
	Hybrid = 'hybrid',
	Control = 'control'
}
/**
 * `AvailableDevice` represents an device that can be added to routing or coverage.
 */
export interface AvailableDevice {
	model: DeviceModel;
	category: DeviceCategory;
}
