import { Inject, Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { map, Observable } from 'rxjs';

import { LoggingConfig } from '../models';

import { ILoggingConfigProvider, LOGGING_CONFIG } from './logging-config.provider';

@Injectable()
export class SessionStorageLoggingConfigProvider implements ILoggingConfigProvider {
	constructor(
		@Inject(LOGGING_CONFIG) private loggingConfig: LoggingConfig,
		private sessionStorageService: SessionStorageService
	) {}

	public get config$(): Observable<LoggingConfig> {
		const config$ = this.sessionStorageService.observe('loggingConfig').pipe(
			map((value) => {
				if (!value) {
					this.sessionStorageService.store('loggingConfig', this.loggingConfig);
					return this.loggingConfig;
				}
				return value;
			})
		);
		return config$;
	}
}
