export enum LicenseState {
	Clockwindback = 'CLOCKWINDBACK',
	Expired = 'EXPIRED',
	FailedActivationKeyError = 'FAILED_ACTIVATION_KEY_ERROR',
	Grace = 'GRACE',
	InternalError = 'INTERNAL_ERROR',
	PendingActivationInsufficient = 'PENDING_ACTIVATION_INSUFFICIENT',
	PendingActivationNetwork = 'PENDING_ACTIVATION_NETWORK',
	PendingActivationOffline = 'PENDING_ACTIVATION_OFFLINE',
	PendingDeactivation = 'PENDING_DEACTIVATION',
	PendingExpiration = 'PENDING_EXPIRATION',
	Tampered = 'TAMPERED',
	Valid = 'VALID'
}
