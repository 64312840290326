import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@jsverse/transloco';

export interface FormDataDetail {
	label: string;
	name: string;
	validators: ValidatorFn[] | [];
	type: string;
	data?: string[];
	disabled?: boolean;
	dependsOn?: string;
}
export interface DataObjectType {
	[key: string]: number | string | string[] | object[] | Date | undefined;
}
export interface DialogDto {
	name?: string;
	description?: string;
	number?: string;
	notes?: string;
	role?: string;
	userId?: string;
	orgId?: string;
	userDescription?: string;
	startDate?: string;
	reasonToDelete?: string;
	expiryTime?: string;
	expirationTime?: string;
}

export interface LocalData {
	action: string;
	formData: FormDataDetail[];
	data?: DataObjectType;
	buttonListType?: string;
}

export interface DialogDataResponse {
	data?: DialogDto;
}

export enum FormType {
	Select = 'select',
	Date = 'date',
	Text = 'text',
	Number = 'number',
	Email = 'email',
	Time = 'time'
}

@Component({
	standalone: true,
	selector: 'sh-dialog-box-component',
	templateUrl: './dialog-box.component.html',
	styleUrls: ['./dialog-box.component.scss'],
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		FlexLayoutModule,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		MatFormFieldModule,
		MatCardModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule
	]
})
export class ShDialogBoxComponent implements OnInit {
	public action: string | undefined;
	public localData: LocalData | undefined;
	public formGroup: FormGroup = new FormGroup({});
	public formData: FormDataDetail[] | undefined = [];
	public title = '';
	public formType = FormType;
	public minDate: Date;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ShDialogBoxComponent>
	) {
		this.minDate = new Date();
	}

	public ngOnInit(): void {
		this.formData = this.localData?.formData;
		this.action = this.localData?.action;

		this.formGroup = this.formBuilder.group({});
		this.formData?.forEach((formControlDetail) => {
			this.formGroup.addControl(formControlDetail.name, new FormControl('', formControlDetail.validators));
			if (formControlDetail.disabled) {
				this.formGroup.controls[formControlDetail.name].disable();
			}
		});
		if (this.localData?.data) {
			this.formGroup.patchValue(this.localData?.data);
		}
	}

	public doAction(): void {
		if (this.formGroup.valid) {
			this.dialogRef.close({ data: this.formGroup.value });
		}
	}

	public closeDialog(): void {
		this.dialogRef.close();
	}
}
