<div *ngIf="cellData" class="sh-status-cell">
	<mat-icon
		*ngIf="!!display"
		[class]="display.class"
		fontSet="prism-mat"
		[fontIcon]="display.icon"
		[matTooltip]="display.message"
		matTooltipPosition="above"
	></mat-icon>
</div>
