/* libs/cloud/shared/ui/components/src/lib/dialogs/info/info-dialog.component.scss */
:host .sh-warning-icon {
  margin-right: 10px;
  vertical-align: text-top;
}
:host .sh-info-dialog-title {
  padding: 1em 1em 0.5em 1em;
}
:host .sh-info-dialog-body {
  margin: 0 1.5em 0 1.5em;
}
:host #sh-info-dialog-ok-button {
  margin: 1em;
}
:host #sh-info-dialog-ok-button,
:host .mdc-dialog__content {
  padding: 0;
}
:host .sh-info-dialog-body > span {
  display: block;
}
::ng-deep .sh-info-dialog-a {
  color: #ffffff !important;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.008em;
  font-weight: 400 !important;
  position: relative;
  padding: 0px 4px 2px 4px;
  border-radius: 0px !important;
  height: 24px !important;
}
::ng-deep .sh-info-dialog-a::after {
  display: block;
  border-bottom: 0.13rem solid #b2ff33;
  content: " ";
  width: 95%;
  left: 2px;
  position: absolute;
  bottom: 0px;
}
::ng-deep .sh-info-dialog-a:hover {
  background-color: #b2ff33;
  color: #000000 !important;
}
/*# sourceMappingURL=info-dialog.component.css.map */
