/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { PipesModule } from '@shure/cloud/shared/ui/pipes';

@Component({
	selector: 'sh-no-rows-overlay',
	templateUrl: './no-rows-overlay.component.html',
	styleUrls: ['./no-rows-overlay.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslocoModule, PipesModule]
})
export class NoRowsOverlayComponent {
	public params!: any;

	public agInit(params: any): void {
		this.params = params;
	}
}
