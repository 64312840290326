import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
	SidenavLayoutComponent,
	SidenavLayoutTopBannerComponent,
	SidenavLayoutSidenavComponent,
	SidenavLayoutContentComponent,
	SidenavLayoutFooterComponent
} from './sidenav-layout.component';

@NgModule({
	imports: [CommonModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatButtonModule],
	declarations: [
		SidenavLayoutComponent,
		SidenavLayoutTopBannerComponent,
		SidenavLayoutSidenavComponent,
		SidenavLayoutContentComponent,
		SidenavLayoutFooterComponent
	],
	exports: [
		SidenavLayoutComponent,
		SidenavLayoutTopBannerComponent,
		SidenavLayoutSidenavComponent,
		SidenavLayoutContentComponent,
		SidenavLayoutFooterComponent
	]
})
export class SidenavLayoutModule {}
