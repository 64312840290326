/* libs/cloud/shared/ui/components/src/lib/cell-renderers/icon-button-renderer/icon-button-renderer.component.scss */
:host ::ng-deep .mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 32px;
  padding: 0;
}
:host .icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--prism-color-theme-ink);
}
/*# sourceMappingURL=icon-button-renderer.component.css.map */
