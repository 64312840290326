/* libs/cloud/shared/ui/components/src/lib/dialogs/confirm/confirm-dialog.component.scss */
:host .sh-warning-icon {
  margin-right: 10px;
  vertical-align: text-top;
}
:host .mat-mdc-card-actions {
  margin-bottom: 0;
}
:host .mat-mdc-dialog-content {
  margin-top: -20px;
}
:host .sh-mat-dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}
:host .sh-mat-dialog-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
:host #sh-mat-dialog-div {
  gap: 10px;
}
/*# sourceMappingURL=confirm-dialog.component.css.map */
