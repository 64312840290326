/* libs/cloud/shared/ui/components/src/lib/legacy-layout/center-card-layout/center-card-layout.component.scss */
:host {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 !important;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
  display: block !important;
}
:host::-webkit-scrollbar-button,
:host::-webkit-scrollbar-track-piece,
:host::-webkit-scrollbar-corner,
:host::-webkit-resizer {
  display: none;
}
:host:hover::-webkit-scrollbar {
  display: initial;
}
:host::-webkit-scrollbar {
  display: none;
}
:host[sh-content-padding] > div > div > div {
  padding: 24px;
}
:host > div {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  flex: 1;
}
:host > div > div {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}
:host > div > div > div {
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 0;
  overflow: hidden;
  background: var(--mat-background-card);
  color: var(--mat-foreground-text);
}
:host > div > div > div ::ng-deep > .mat-mdc-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}
:host > div > div.small {
  max-width: 400px;
}
:host > div > div.medium {
  max-width: 800px;
}
:host > div > div.large {
  max-width: 1200px;
}
:host > div > div.full {
  max-width: 100%;
}
:host > div > div.full > div {
  margin-left: 24px;
  margin-right: 24px;
}
/*# sourceMappingURL=center-card-layout.component.css.map */
