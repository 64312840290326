/* libs/cloud/shared/ui/components/src/lib/legacy-layout/secondarynav-layout/secondarynav-layout.component.scss */
:host {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  word-wrap: break-word;
}
:host .sh-complex-layout-container {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
@media only screen and (max-width: 318px) {
  :host .mat-drawer {
    margin-left: 104px;
    width: 55%;
  }
}
@media (min-width: 318px) and (max-width: 480px) {
  :host .mat-drawer {
    margin-left: 104px;
    width: 40%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  :host .mat-drawer {
    margin-left: 104px;
    width: 30%;
  }
}
@media (min-width: 769px) and (max-width: 848px) {
  :host .mat-drawer {
    margin-left: 104px;
    width: 20%;
  }
}
@media (min-width: 848px) and (max-width: 959px) {
  :host .mat-drawer {
    margin-left: 104px;
    width: 15%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 960px) {
  :host .mat-drawer {
    width: 15%;
    margin-left: 0 !important;
  }
}
@media (min-width: 961px) and (max-width: 1112px) {
  :host .mat-drawer {
    width: 15%;
  }
}
:host ::ng-deep .mat-drawer-inner-container {
  overflow-y: overlay !important;
}
:host ::ng-deep .mat-drawer-inner-container > .mat-mdc-icon-button {
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 3;
}
:host ::ng-deep .mat-drawer-inner-container:hover::-webkit-scrollbar {
  display: initial;
}
:host ::ng-deep .mat-drawer-inner-container {
  position: relative;
}
:host ::ng-deep .mat-drawer.mat-drawer-side {
  z-index: 1;
  border-top: none;
}
:host ::ng-deep .mat-secnav-container {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host ::ng-deep .mat-secnav-content {
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host ::ng-deep .mat-drawer-backdrop {
  z-index: 2 !important;
}
:host ::ng-deep .mdc-list-item__primary-text {
  word-wrap: break-word;
  white-space: normal !important;
}
/*# sourceMappingURL=secondarynav-layout.component.css.map */
