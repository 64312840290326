import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DeviceImageComponent } from './device-image.component';
@NgModule({
	declarations: [DeviceImageComponent],
	exports: [DeviceImageComponent],
	providers: [],
	imports: [CommonModule]
})
export class DeviceImageModule {}
