/* libs/cloud/shared/ui/components/src/lib/language-selector/language-selector.component.scss */
:host {
  height: 100%;
  display: block;
}
mat-form-field {
  width: 100%;
}
:host ::ng-deep .mat-mdc-form-field-infix {
  padding-top: 23px !important;
}
@media (min-width: 481px) and (max-width: 932px) and (orientation: landscape) {
  .sh-language-mat-option {
    min-height: 45px !important;
  }
}
/*# sourceMappingURL=language-selector.component.css.map */
