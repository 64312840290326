<div fxLayout="row" fxLayoutGap="16px" *transloco="let t; read: 'components.zoom-controls-buttons'">
	<button mat-raised-button id="sh-zoom-control-zoom-in" class="mat-elevation-z4" (click)="zoomIn.emit()">
		<mat-icon fontSet="prism-mat" fontIcon="zoom_in"> </mat-icon>
	</button>
	<button mat-raised-button id="sh-zoom-control-zoom-out" class="mat-elevation-z4" (click)="zoomOut.emit()">
		<mat-icon fontSet="prism-mat" fontIcon="zoom_out"> </mat-icon>
	</button>
	<button mat-raised-button id="sh-zoom-control-fit" class="mat-elevation-z4" (click)="fit.emit()">
		{{ t('fit') }}
	</button>
</div>
