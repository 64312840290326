<div class="sh-search-container">
	<mat-icon fontIcon="search"></mat-icon>

	<input
		class="sh-search-input"
		[placeholder]="'cloud.shared.search-bar.default-placeholder' | transloco"
		[(ngModel)]="queryString"
		(ngModelChange)="queryChanged.emit(queryString)"
		(keyup.enter)="querySubmitted.emit(queryString)"
		type="text"
	/>
	<button
		class="sh-search-button-arrow"
		*ngIf="queryString.length > 0"
		(click)="querySubmitted.emit(queryString)"
		mat-icon-button
	>
		<mat-icon fontIcon="arrow_forward"></mat-icon>
	</button>

	<ng-content></ng-content>
</div>
