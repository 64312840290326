import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from './confirm/confirm-dialog.component';
import { ConfirmDialogData } from './confirm/confirm-dialog.component.models';
import { CookieDialogComponent } from './cookie-dialog/cookie-dialog.component';
import { InfoDialogComponent, InfoDialogData } from './info/info-dialog.component';
export interface DialogLocalStorage {
	doNotShowAgain: string[];
}

export const EMPTY_IGNORED_DIALOGS = { doNotShowAgain: [] };

@Injectable({ providedIn: 'root' })
export class CommonDialogsService {
	public ignoredDialogs$ = new ReplaySubject<DialogLocalStorage>(1);
	private readonly IGNOREDIALOGS_LOCAL_STORAGE_KEY = 'ignore_confirmation_dialogs';

	private readonly defaultDialogConfig: MatDialogConfig = {
		width: '400px'
	};

	constructor(private readonly dialog: MatDialog) {
		this.ignoredDialogs$.next(this.readIgnoredDialogs());
	}

	/**
	 * Open confirm dialog
	 */
	public openConfirmDialog(
		data: ConfirmDialogData,
		config: MatDialogConfig = this.defaultDialogConfig
	): Observable<boolean> {
		if (data.doNotShowDialogId && this.isDialogIgnored(data.doNotShowDialogId)) {
			return of(true);
		}

		return this.dialog
			.open(ConfirmDialogComponent, {
				...config,
				data: {
					...data,
					body: typeof data.body === 'string' ? [data.body] : data.body
				}
			})
			.afterClosed()
			.pipe(
				map((result) => {
					if (!result || !result.confirmed) {
						return false;
					}

					this.setIgnoredDialog(result.doNotShowDialog, data.doNotShowDialogId);

					return result.confirmed || false;
				})
			);
	}

	/**
	 * Open a info dialog
	 */
	public openInfoDialog(data: InfoDialogData, config: MatDialogConfig = this.defaultDialogConfig): void {
		this.dialog.open(InfoDialogComponent, {
			...config,
			data: {
				title: data.title,
				body: typeof data.body === 'string' ? [data.body] : data.body,
				okLabel: data.okLabel,
				showWarningIcon: data.showWarningIcon
			}
		});
	}

	public clearIgnoredDialogs(): void {
		this.writeIgnoredDialogs(EMPTY_IGNORED_DIALOGS);
		this.ignoredDialogs$.next(EMPTY_IGNORED_DIALOGS);
	}

	public setIgnoredDialog(doNotShowDialog: boolean, id?: string): void {
		if (id) {
			const ignoredDialogs = this.readIgnoredDialogs();
			if (doNotShowDialog && !ignoredDialogs.doNotShowAgain.includes(id)) {
				ignoredDialogs.doNotShowAgain.push(id);
			}
			this.writeIgnoredDialogs(ignoredDialogs);
		}
	}

	public isDialogIgnored(id: string): boolean {
		const ignoredDialogs = this.readIgnoredDialogs();
		return ignoredDialogs.doNotShowAgain.indexOf(id) > -1;
	}
	/**
	 * Open a cookie dialog
	 */
	public openCookieDialog(): void {
		this.dialog.open(CookieDialogComponent, {
			disableClose: true,
			width: '400px',
			position: { top: '10%' },
			backdropClass: 'cookie-dialog-backdrop'
		});
	}

	private readIgnoredDialogs(): DialogLocalStorage {
		const ignoredDialogs = navigator.cookieEnabled
			? localStorage.getItem(this.IGNOREDIALOGS_LOCAL_STORAGE_KEY)
			: '';
		if (!ignoredDialogs) {
			return EMPTY_IGNORED_DIALOGS;
		}
		return JSON.parse(ignoredDialogs);
	}

	private writeIgnoredDialogs(ignoredDialogs: DialogLocalStorage): void {
		localStorage.setItem(this.IGNOREDIALOGS_LOCAL_STORAGE_KEY, JSON.stringify(ignoredDialogs));
		this.ignoredDialogs$.next(ignoredDialogs);
	}
}
