import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';

export interface SnackbarData {
	message: string;
	dismissText: string;
}

@Component({
	selector: 'sh-html-snackbar',
	templateUrl: './html-snackbar.component.html',
	styleUrls: ['./html-snackbar.component.scss'],
	standalone: true,
	imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatSnackBarModule]
})
export class HtmlSnackbarComponent {
	constructor(
		public readonly snackBarRef: MatSnackBarRef<HtmlSnackbarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public readonly data: SnackbarData
	) {}
}
