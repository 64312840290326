<input
	class="sh-text-input-element"
	matInput
	fxFlex
	type="text"
	[value]="displayValue"
	(input)="updateDisplayValue(input.value)"
	(focus)="focus()"
	(blur)="updateFormValue(input.value)"
	(keydown.enter)="$any($event.target)?.blur()"
	(keydown.esc)="undoChanges()"
	[placeholder]="placeholder"
	[disabled]="disabled"
	[readonly]="readonly"
	[required]="required"
	[attr.minlength]="minLength"
	[attr.maxlength]="maxLength"
	[errorStateMatcher]="errorStateMatcher"
	#input
/>
