import { inject, Injector } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';

import { APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const oktaSessionGuard: CanActivateFn = (route, state) => {
	const oktaAuth = inject(OKTA_AUTH);
	const router = inject(Router);
	const injector = inject(Injector);
	const appEnv = injector.get(APP_ENVIRONMENT);

	return oktaAuth.session.exists().then((result: boolean) => {
		if (result) {
			return result;
		}

		oktaAuth.tokenManager.clear();
		switch (appEnv.appType) {
			case 'admin':
				router.navigate(['/signin']);
				return true;

			case 'device':
				// Note: This mirrors the logic in the oktaModule.onAuthRequired method
				// If we don't have a previous state, that means the user
				// is navigating directly to this page. So, we should go to the
				// /signin route. Otherwise, redirect to the logout URI.
				if (!oktaAuth.authStateManager.getPreviousAuthState() || !oktaAuth.options.postLogoutRedirectUri) {
					router.navigate(['/signin']);
				} else {
					// because the redirectURI is most likely a separate app or domain
					// we can't use router.navigate.
					window.location.href = oktaAuth.options.postLogoutRedirectUri;
				}
				return true;

			default:
				router.navigate(['/']);
				return true;
		}
	});
};
