import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
	SecnavLayoutComponent,
	SecnavLayoutTopBannerComponent,
	SecnavLayoutsecnavComponent,
	SecnavLayoutContentComponent,
	SecnavLayoutFooterComponent
} from './secondarynav-layout.component';

@NgModule({
	imports: [CommonModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatButtonModule],
	declarations: [
		SecnavLayoutComponent,
		SecnavLayoutTopBannerComponent,
		SecnavLayoutsecnavComponent,
		SecnavLayoutContentComponent,
		SecnavLayoutFooterComponent
	],
	exports: [
		SecnavLayoutComponent,
		SecnavLayoutTopBannerComponent,
		SecnavLayoutsecnavComponent,
		SecnavLayoutContentComponent,
		SecnavLayoutFooterComponent
	]
})
export class SecondaryNavLayoutModule {}
