import { __decorate, __metadata } from 'tslib';
import * as i0 from '@angular/core';
import { ChangeDetectorRef, ElementRef, NgZone, Component, ChangeDetectionStrategy, Directive, HostListener } from '@angular/core';
import { fromEvent } from 'rxjs';
import { defineCustomElement as defineCustomElement$1 } from '@shure/prism-web-components/dist/components/prism-battery.js';
import { defineCustomElement as defineCustomElement$2 } from '@shure/prism-web-components/dist/components/prism-battery-bar.js';
import { defineCustomElement as defineCustomElement$3 } from '@shure/prism-web-components/dist/components/prism-battery-gauge.js';
import { defineCustomElement as defineCustomElement$4 } from '@shure/prism-web-components/dist/components/prism-battery-indicator.js';
import { defineCustomElement as defineCustomElement$5 } from '@shure/prism-web-components/dist/components/prism-beta-indicator.js';
import { defineCustomElement as defineCustomElement$6 } from '@shure/prism-web-components/dist/components/prism-breadcrumb.js';
import { defineCustomElement as defineCustomElement$7 } from '@shure/prism-web-components/dist/components/prism-breadcrumb-item.js';
import { defineCustomElement as defineCustomElement$8 } from '@shure/prism-web-components/dist/components/prism-button.js';
import { defineCustomElement as defineCustomElement$9 } from '@shure/prism-web-components/dist/components/prism-button-group.js';
import { defineCustomElement as defineCustomElement$a } from '@shure/prism-web-components/dist/components/prism-callout.js';
import { defineCustomElement as defineCustomElement$b } from '@shure/prism-web-components/dist/components/prism-card.js';
import { defineCustomElement as defineCustomElement$c } from '@shure/prism-web-components/dist/components/prism-checkbox.js';
import { defineCustomElement as defineCustomElement$d } from '@shure/prism-web-components/dist/components/prism-chip.js';
import { defineCustomElement as defineCustomElement$e } from '@shure/prism-web-components/dist/components/prism-dialog.js';
import { defineCustomElement as defineCustomElement$f } from '@shure/prism-web-components/dist/components/prism-floating-dialog.js';
import { defineCustomElement as defineCustomElement$g } from '@shure/prism-web-components/dist/components/prism-floating-dialog-base.js';
import { defineCustomElement as defineCustomElement$h } from '@shure/prism-web-components/dist/components/prism-icon.js';
import { defineCustomElement as defineCustomElement$i } from '@shure/prism-web-components/dist/components/prism-icon-button.js';
import { defineCustomElement as defineCustomElement$j } from '@shure/prism-web-components/dist/components/prism-input.js';
import { defineCustomElement as defineCustomElement$k } from '@shure/prism-web-components/dist/components/prism-layout.js';
import { defineCustomElement as defineCustomElement$l } from '@shure/prism-web-components/dist/components/prism-link-info.js';
import { defineCustomElement as defineCustomElement$m } from '@shure/prism-web-components/dist/components/prism-list.js';
import { defineCustomElement as defineCustomElement$n } from '@shure/prism-web-components/dist/components/prism-list-item.js';
import { defineCustomElement as defineCustomElement$o } from '@shure/prism-web-components/dist/components/prism-menu.js';
import { defineCustomElement as defineCustomElement$p } from '@shure/prism-web-components/dist/components/prism-menu-base.js';
import { defineCustomElement as defineCustomElement$q } from '@shure/prism-web-components/dist/components/prism-menu-item.js';
import { defineCustomElement as defineCustomElement$r } from '@shure/prism-web-components/dist/components/prism-mute-button.js';
import { defineCustomElement as defineCustomElement$s } from '@shure/prism-web-components/dist/components/prism-nav.js';
import { defineCustomElement as defineCustomElement$t } from '@shure/prism-web-components/dist/components/prism-nav-button.js';
import { defineCustomElement as defineCustomElement$u } from '@shure/prism-web-components/dist/components/prism-nav-group.js';
import { defineCustomElement as defineCustomElement$v } from '@shure/prism-web-components/dist/components/prism-nav-toggle-button.js';
import { defineCustomElement as defineCustomElement$w } from '@shure/prism-web-components/dist/components/prism-number-input.js';
import { defineCustomElement as defineCustomElement$x } from '@shure/prism-web-components/dist/components/prism-profile-image.js';
import { defineCustomElement as defineCustomElement$y } from '@shure/prism-web-components/dist/components/prism-progress-dots.js';
import { defineCustomElement as defineCustomElement$z } from '@shure/prism-web-components/dist/components/prism-progress-indicator.js';
import { defineCustomElement as defineCustomElement$A } from '@shure/prism-web-components/dist/components/prism-radio-button.js';
import { defineCustomElement as defineCustomElement$B } from '@shure/prism-web-components/dist/components/prism-radio-group.js';
import { defineCustomElement as defineCustomElement$C } from '@shure/prism-web-components/dist/components/prism-rf-meter.js';
import { defineCustomElement as defineCustomElement$D } from '@shure/prism-web-components/dist/components/prism-search-bar.js';
import { defineCustomElement as defineCustomElement$E } from '@shure/prism-web-components/dist/components/prism-segmented-picker.js';
import { defineCustomElement as defineCustomElement$F } from '@shure/prism-web-components/dist/components/prism-select.js';
import { defineCustomElement as defineCustomElement$G } from '@shure/prism-web-components/dist/components/prism-slider.js';
import { defineCustomElement as defineCustomElement$H } from '@shure/prism-web-components/dist/components/prism-snackbar.js';
import { defineCustomElement as defineCustomElement$I } from '@shure/prism-web-components/dist/components/prism-status-indicator.js';
import { defineCustomElement as defineCustomElement$J } from '@shure/prism-web-components/dist/components/prism-switch.js';
import { defineCustomElement as defineCustomElement$K } from '@shure/prism-web-components/dist/components/prism-toolbar.js';
import { defineCustomElement as defineCustomElement$L } from '@shure/prism-web-components/dist/components/prism-tooltip.js';
import { defineCustomElement as defineCustomElement$M } from '@shure/prism-web-components/dist/components/prism-tooltip-text.js';
import { defineCustomElement as defineCustomElement$N } from '@shure/prism-web-components/dist/components/prism-transmitter-info.js';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/* eslint-disable */
/* tslint:disable */
const proxyInputs = (Cmp, inputs) => {
    const Prototype = Cmp.prototype;
    inputs.forEach((item) => {
        Object.defineProperty(Prototype, item, {
            get() {
                return this.el[item];
            },
            set(val) {
                this.z.runOutsideAngular(() => (this.el[item] = val));
            },
            /**
             * In the event that proxyInputs is called
             * multiple times re-defining these inputs
             * will cause an error to be thrown. As a result
             * we set configurable: true to indicate these
             * properties can be changed.
             */
            configurable: true,
        });
    });
};
const proxyMethods = (Cmp, methods) => {
    const Prototype = Cmp.prototype;
    methods.forEach((methodName) => {
        Prototype[methodName] = function () {
            const args = arguments;
            return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
        };
    });
};
const proxyOutputs = (instance, el, events) => {
    events.forEach((eventName) => (instance[eventName] = fromEvent(el, eventName)));
};
const defineCustomElement = (tagName, customElement) => {
    if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
        customElements.define(tagName, customElement);
    }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
    const decorator = function (cls) {
        const { defineCustomElementFn, inputs, methods } = opts;
        if (defineCustomElementFn !== undefined) {
            defineCustomElementFn();
        }
        if (inputs) {
            proxyInputs(cls, inputs);
        }
        if (methods) {
            proxyMethods(cls, methods);
        }
        return cls;
    };
    return decorator;
}

let PrismBatteryComponent = class PrismBatteryComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBatteryComponent, isStandalone: true, selector: "prism-battery", inputs: { lowLevelEnd: "lowLevelEnd", mediumLevelEnd: "mediumLevelEnd", state: "state", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBatteryComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$1,
        inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBatteryComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-battery',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismBatteryBarComponent = class PrismBatteryBarComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryBarComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBatteryBarComponent, isStandalone: true, selector: "prism-battery-bar", inputs: { lowLevelEnd: "lowLevelEnd", mediumLevelEnd: "mediumLevelEnd", state: "state", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBatteryBarComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$2,
        inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBatteryBarComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryBarComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-battery-bar',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['lowLevelEnd', 'mediumLevelEnd', 'state', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismBatteryGaugeComponent = class PrismBatteryGaugeComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryGaugeComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBatteryGaugeComponent, isStandalone: true, selector: "prism-battery-gauge", inputs: { gaugeState: "gaugeState", lowLevelEnd: "lowLevelEnd", mediumLevelEnd: "mediumLevelEnd", radius: "radius", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBatteryGaugeComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$3,
        inputs: ['gaugeState', 'lowLevelEnd', 'mediumLevelEnd', 'radius', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBatteryGaugeComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryGaugeComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-battery-gauge',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['gaugeState', 'lowLevelEnd', 'mediumLevelEnd', 'radius', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismBatteryIndicatorComponent = class PrismBatteryIndicatorComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryIndicatorComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBatteryIndicatorComponent, isStandalone: true, selector: "prism-battery-indicator", inputs: { state: "state", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBatteryIndicatorComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$4,
        inputs: ['state', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBatteryIndicatorComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBatteryIndicatorComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-battery-indicator',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['state', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismBetaIndicatorComponent = class PrismBetaIndicatorComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBetaIndicatorComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBetaIndicatorComponent, isStandalone: true, selector: "prism-beta-indicator", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBetaIndicatorComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$5
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBetaIndicatorComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBetaIndicatorComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-beta-indicator',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismBreadcrumbComponent = class PrismBreadcrumbComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBreadcrumbComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBreadcrumbComponent, isStandalone: true, selector: "prism-breadcrumb", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBreadcrumbComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$6
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBreadcrumbComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBreadcrumbComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-breadcrumb',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismBreadcrumbItemComponent = class PrismBreadcrumbItemComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['itemSelected']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBreadcrumbItemComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismBreadcrumbItemComponent, isStandalone: true, selector: "prism-breadcrumb-item", inputs: { disabled: "disabled", href: "href", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismBreadcrumbItemComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$7,
        inputs: ['disabled', 'href', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismBreadcrumbItemComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismBreadcrumbItemComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-breadcrumb-item',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'href', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismButtonComponent = class PrismButtonComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismButtonComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismButtonComponent, isStandalone: true, selector: "prism-button", inputs: { checked: "checked", disabled: "disabled", size: "size", swap: "swap", variant: "variant" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismButtonComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$8,
        inputs: ['checked', 'disabled', 'size', 'swap', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismButtonComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismButtonComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['checked', 'disabled', 'size', 'swap', 'variant'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismButtonGroupComponent = class PrismButtonGroupComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismButtonGroupComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismButtonGroupComponent, isStandalone: true, selector: "prism-button-group", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismButtonGroupComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$9
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismButtonGroupComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismButtonGroupComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-button-group',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismCalloutComponent = class PrismCalloutComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismCalloutComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismCalloutComponent, isStandalone: true, selector: "prism-callout", inputs: { variant: "variant" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismCalloutComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$a,
        inputs: ['variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismCalloutComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismCalloutComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-callout',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['variant'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismCardComponent = class PrismCardComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismCardComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismCardComponent, isStandalone: true, selector: "prism-card", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismCardComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$b
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismCardComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismCardComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-card',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismCheckboxComponent = class PrismCheckboxComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismCheckboxComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismCheckboxComponent, isStandalone: true, selector: "prism-checkbox", inputs: { checked: "checked", disabled: "disabled", indeterminate: "indeterminate", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismCheckboxComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$c,
        inputs: ['checked', 'disabled', 'indeterminate', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismCheckboxComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismCheckboxComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-checkbox',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['checked', 'disabled', 'indeterminate', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismChipComponent = class PrismChipComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['close']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismChipComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismChipComponent, isStandalone: true, selector: "prism-chip", inputs: { disabled: "disabled", isInput: "isInput", selected: "selected" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismChipComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$d,
        inputs: ['disabled', 'isInput', 'selected']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismChipComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismChipComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-chip',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'isInput', 'selected'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismDialogComponent = class PrismDialogComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['confirm', 'cancel', 'visibleChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismDialogComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismDialogComponent, isStandalone: true, selector: "prism-dialog", inputs: { cancelButton: "cancelButton", cancelButtonDisabled: "cancelButtonDisabled", confirmButton: "confirmButton", confirmButtonDisabled: "confirmButtonDisabled", modal: "modal", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismDialogComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$e,
        inputs: ['cancelButton', 'cancelButtonDisabled', 'confirmButton', 'confirmButtonDisabled', 'modal', 'visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismDialogComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismDialogComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-dialog',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['cancelButton', 'cancelButtonDisabled', 'confirmButton', 'confirmButtonDisabled', 'modal', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismFloatingDialogComponent = class PrismFloatingDialogComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['visibleChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismFloatingDialogComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismFloatingDialogComponent, isStandalone: true, selector: "prism-floating-dialog", inputs: { visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismFloatingDialogComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$f,
        inputs: ['visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismFloatingDialogComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismFloatingDialogComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-floating-dialog',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismFloatingDialogBaseComponent = class PrismFloatingDialogBaseComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['closeDialog']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismFloatingDialogBaseComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismFloatingDialogBaseComponent, isStandalone: true, selector: "prism-floating-dialog-base", inputs: { visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismFloatingDialogBaseComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$g,
        inputs: ['visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismFloatingDialogBaseComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismFloatingDialogBaseComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-floating-dialog-base',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismIconComponent = class PrismIconComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismIconComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismIconComponent, isStandalone: true, selector: "prism-icon", inputs: { customIcon: "customIcon", icon: "icon", size: "size" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismIconComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$h,
        inputs: ['customIcon', 'icon', 'size']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismIconComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismIconComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-icon',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['customIcon', 'icon', 'size'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismIconButtonComponent = class PrismIconButtonComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismIconButtonComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismIconButtonComponent, isStandalone: true, selector: "prism-icon-button", inputs: { checked: "checked", customIcon: "customIcon", disabled: "disabled", icon: "icon", label: "label", size: "size", toggle: "toggle" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismIconButtonComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$i,
        inputs: ['checked', 'customIcon', 'disabled', 'icon', 'label', 'size', 'toggle']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismIconButtonComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismIconButtonComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-icon-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['checked', 'customIcon', 'disabled', 'icon', 'label', 'size', 'toggle'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismInputComponent = class PrismInputComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismInputComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismInputComponent, isStandalone: true, selector: "prism-input", inputs: { autocapitalize: "autocapitalize", autocomplete: "autocomplete", autocorrect: "autocorrect", autofocus: "autofocus", disabled: "disabled", form: "form", inputmode: "inputmode", isValid: "isValid", maxlength: "maxlength", minlength: "minlength", name: "name", pattern: "pattern", placeholder: "placeholder", readonly: "readonly", required: "required", type: "type", validator: "validator", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismInputComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$j,
        inputs: ['autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'disabled', 'form', 'inputmode', 'isValid', 'maxlength', 'minlength', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'type', 'validator', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismInputComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismInputComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-input',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'disabled', 'form', 'inputmode', 'isValid', 'maxlength', 'minlength', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'type', 'validator', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismLayoutComponent = class PrismLayoutComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['pwcToggleNavButton']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismLayoutComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismLayoutComponent, isStandalone: true, selector: "prism-layout", inputs: { inMobileView: "inMobileView", mobileMenuOpen: "mobileMenuOpen", secondaryNavOpen: "secondaryNavOpen" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismLayoutComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$k,
        inputs: ['inMobileView', 'mobileMenuOpen', 'secondaryNavOpen']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismLayoutComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismLayoutComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-layout',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['inMobileView', 'mobileMenuOpen', 'secondaryNavOpen'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismLinkInfoComponent = class PrismLinkInfoComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismLinkInfoComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismLinkInfoComponent, isStandalone: true, selector: "prism-link-info", inputs: { linked: "linked" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismLinkInfoComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$l,
        inputs: ['linked']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismLinkInfoComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismLinkInfoComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-link-info',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['linked'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismListComponent = class PrismListComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismListComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismListComponent, isStandalone: true, selector: "prism-list", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismListComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$m
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismListComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismListComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-list',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismListItemComponent = class PrismListItemComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismListItemComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismListItemComponent, isStandalone: true, selector: "prism-list-item", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismListItemComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$n
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismListItemComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismListItemComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-list-item',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismMenuComponent = class PrismMenuComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange', 'visibleChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMenuComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismMenuComponent, isStandalone: true, selector: "prism-menu", inputs: { placement: "placement", submenu: "submenu", value: "value", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismMenuComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$o,
        inputs: ['placement', 'submenu', 'value', 'visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismMenuComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMenuComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-menu',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['placement', 'submenu', 'value', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismMenuBaseComponent = class PrismMenuBaseComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMenuBaseComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismMenuBaseComponent, isStandalone: true, selector: "prism-menu-base", inputs: { editableSelectMenu: "editableSelectMenu", selectMenu: "selectMenu", submenu: "submenu", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismMenuBaseComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$p,
        inputs: ['editableSelectMenu', 'selectMenu', 'submenu', 'visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismMenuBaseComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMenuBaseComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-menu-base',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['editableSelectMenu', 'selectMenu', 'submenu', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismMenuItemComponent = class PrismMenuItemComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['itemSelected']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMenuItemComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismMenuItemComponent, isStandalone: true, selector: "prism-menu-item", inputs: { active: "active", disabled: "disabled", selectMenuItem: "selectMenuItem", selected: "selected", submenu: "submenu", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismMenuItemComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$q,
        inputs: ['active', 'disabled', 'selectMenuItem', 'selected', 'submenu', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismMenuItemComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMenuItemComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-menu-item',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['active', 'disabled', 'selectMenuItem', 'selected', 'submenu', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismMuteButtonComponent = class PrismMuteButtonComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMuteButtonComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismMuteButtonComponent, isStandalone: true, selector: "prism-mute-button", inputs: { disabled: "disabled", icon: "icon", link: "link", linkDisabled: "linkDisabled", linked: "linked", muteText: "muteText", muted: "muted", unmuteText: "unmuteText" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismMuteButtonComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$r,
        inputs: ['disabled', 'icon', 'link', 'linkDisabled', 'linked', 'muteText', 'muted', 'unmuteText']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismMuteButtonComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismMuteButtonComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-mute-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'icon', 'link', 'linkDisabled', 'linked', 'muteText', 'muted', 'unmuteText'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismNavComponent = class PrismNavComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismNavComponent, isStandalone: true, selector: "prism-nav", inputs: { accent: "accent", hasSecondaryNav: "hasSecondaryNav", secondary: "secondary", smallScreen: "smallScreen" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismNavComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$s,
        inputs: ['accent', 'hasSecondaryNav', 'secondary', 'smallScreen']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismNavComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-nav',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['accent', 'hasSecondaryNav', 'secondary', 'smallScreen'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismNavButtonComponent = class PrismNavButtonComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['pwcNavButtonSelected']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavButtonComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismNavButtonComponent, isStandalone: true, selector: "prism-nav-button", inputs: { accent: "accent", buttonId: "buttonId", disabled: "disabled", expanded: "expanded", href: "href", secondary: "secondary", selected: "selected", smallScreen: "smallScreen", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismNavButtonComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$t,
        inputs: ['accent', 'buttonId', 'disabled', 'expanded', 'href', 'secondary', 'selected', 'smallScreen', 'visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismNavButtonComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavButtonComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-nav-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['accent', 'buttonId', 'disabled', 'expanded', 'href', 'secondary', 'selected', 'smallScreen', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismNavGroupComponent = class PrismNavGroupComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavGroupComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismNavGroupComponent, isStandalone: true, selector: "prism-nav-group", inputs: { level: "level", secondary: "secondary" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismNavGroupComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$u,
        inputs: ['level', 'secondary']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismNavGroupComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavGroupComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-nav-group',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['level', 'secondary'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismNavToggleButtonComponent = class PrismNavToggleButtonComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['pwcToggleNav']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavToggleButtonComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismNavToggleButtonComponent, isStandalone: true, selector: "prism-nav-toggle-button", inputs: { disabled: "disabled", isOpen: "isOpen" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismNavToggleButtonComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$v,
        inputs: ['disabled', 'isOpen']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismNavToggleButtonComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNavToggleButtonComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-nav-toggle-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'isOpen'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismNumberInputComponent = class PrismNumberInputComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNumberInputComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismNumberInputComponent, isStandalone: true, selector: "prism-number-input", inputs: { aboveMaxError: "aboveMaxError", belowMinError: "belowMinError", blurAlias: "blurAlias", disabled: "disabled", displayValue: "displayValue", isValid: "isValid", max: "max", min: "min", nonNumericError: "nonNumericError", placeholder: "placeholder", required: "required", requiredError: "requiredError", stacked: "stacked", step: "step", unit: "unit", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismNumberInputComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$w,
        inputs: ['aboveMaxError', 'belowMinError', 'blurAlias', 'disabled', 'displayValue', 'isValid', 'max', 'min', 'nonNumericError', 'placeholder', 'required', 'requiredError', 'stacked', 'step', 'unit', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismNumberInputComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismNumberInputComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-number-input',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['aboveMaxError', 'belowMinError', 'blurAlias', 'disabled', 'displayValue', 'isValid', 'max', 'min', 'nonNumericError', 'placeholder', 'required', 'requiredError', 'stacked', 'step', 'unit', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismProfileImageComponent = class PrismProfileImageComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismProfileImageComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismProfileImageComponent, isStandalone: true, selector: "prism-profile-image", inputs: { firstName: "firstName", image: "image", lastName: "lastName", size: "size" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismProfileImageComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$x,
        inputs: ['firstName', 'image', 'lastName', 'size']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismProfileImageComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismProfileImageComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-profile-image',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['firstName', 'image', 'lastName', 'size'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismProgressDotsComponent = class PrismProgressDotsComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismProgressDotsComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismProgressDotsComponent, isStandalone: true, selector: "prism-progress-dots", ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismProgressDotsComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$y
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismProgressDotsComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismProgressDotsComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-progress-dots',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: [],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismProgressIndicatorComponent = class PrismProgressIndicatorComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismProgressIndicatorComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismProgressIndicatorComponent, isStandalone: true, selector: "prism-progress-indicator", inputs: { mode: "mode", size: "size", type: "type", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismProgressIndicatorComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$z,
        inputs: ['mode', 'size', 'type', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismProgressIndicatorComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismProgressIndicatorComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-progress-indicator',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['mode', 'size', 'type', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismRadioButtonComponent = class PrismRadioButtonComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['radioButtonValueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismRadioButtonComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismRadioButtonComponent, isStandalone: true, selector: "prism-radio-button", inputs: { checked: "checked", disabled: "disabled", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismRadioButtonComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$A,
        inputs: ['checked', 'disabled', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismRadioButtonComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismRadioButtonComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-radio-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['checked', 'disabled', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismRadioGroupComponent = class PrismRadioGroupComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismRadioGroupComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismRadioGroupComponent, isStandalone: true, selector: "prism-radio-group", inputs: { disabled: "disabled", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismRadioGroupComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$B,
        inputs: ['disabled', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismRadioGroupComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismRadioGroupComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-radio-group',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismRfMeterComponent = class PrismRfMeterComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismRfMeterComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismRfMeterComponent, isStandalone: true, selector: "prism-rf-meter", inputs: { status: "status" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismRfMeterComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$C,
        inputs: ['status']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismRfMeterComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismRfMeterComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-rf-meter',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['status'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismSearchBarComponent = class PrismSearchBarComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSearchBarComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismSearchBarComponent, isStandalone: true, selector: "prism-search-bar", inputs: { autocomplete: "autocomplete", disabled: "disabled", placeholder: "placeholder" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismSearchBarComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$D,
        inputs: ['autocomplete', 'disabled', 'placeholder']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismSearchBarComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSearchBarComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-search-bar',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['autocomplete', 'disabled', 'placeholder'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismSegmentedPickerComponent = class PrismSegmentedPickerComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSegmentedPickerComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismSegmentedPickerComponent, isStandalone: true, selector: "prism-segmented-picker", inputs: { disabled: "disabled", name: "name", options: "options", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismSegmentedPickerComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$E,
        inputs: ['disabled', 'name', 'options', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismSegmentedPickerComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSegmentedPickerComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-segmented-picker',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'name', 'options', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismSelectComponent = class PrismSelectComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSelectComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismSelectComponent, isStandalone: true, selector: "prism-select", inputs: { disabled: "disabled", editable: "editable", placement: "placement", value: "value", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismSelectComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$F,
        inputs: ['disabled', 'editable', 'placement', 'value', 'visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismSelectComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSelectComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-select',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'editable', 'placement', 'value', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismSliderComponent = class PrismSliderComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueInput', 'valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSliderComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismSliderComponent, isStandalone: true, selector: "prism-slider", inputs: { disabled: "disabled", fader: "fader", max: "max", min: "min", step: "step", value: "value", vertical: "vertical" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismSliderComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$G,
        inputs: ['disabled', 'fader', 'max', 'min', 'step', 'value', 'vertical']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismSliderComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSliderComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-slider',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['disabled', 'fader', 'max', 'min', 'step', 'value', 'vertical'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismSnackbarComponent = class PrismSnackbarComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['visibleChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSnackbarComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismSnackbarComponent, isStandalone: true, selector: "prism-snackbar", inputs: { duration: "duration", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismSnackbarComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$H,
        inputs: ['duration', 'visible'],
        methods: ['open']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismSnackbarComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSnackbarComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-snackbar',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['duration', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismStatusIndicatorComponent = class PrismStatusIndicatorComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismStatusIndicatorComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismStatusIndicatorComponent, isStandalone: true, selector: "prism-status-indicator", inputs: { shape: "shape", status: "status" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismStatusIndicatorComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$I,
        inputs: ['shape', 'status']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismStatusIndicatorComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismStatusIndicatorComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-status-indicator',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['shape', 'status'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismSwitchComponent = class PrismSwitchComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['valueChange']);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSwitchComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismSwitchComponent, isStandalone: true, selector: "prism-switch", inputs: { checked: "checked", disabled: "disabled", value: "value" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismSwitchComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$J,
        inputs: ['checked', 'disabled', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismSwitchComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismSwitchComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-switch',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['checked', 'disabled', 'value'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismToolbarComponent = class PrismToolbarComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismToolbarComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismToolbarComponent, isStandalone: true, selector: "prism-toolbar", inputs: { showDividers: "showDividers" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismToolbarComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$K,
        inputs: ['showDividers']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismToolbarComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismToolbarComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-toolbar',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['showDividers'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismTooltipComponent = class PrismTooltipComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismTooltipComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismTooltipComponent, isStandalone: true, selector: "prism-tooltip", inputs: { placement: "placement", text: "text" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismTooltipComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$L,
        inputs: ['placement', 'text']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismTooltipComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismTooltipComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-tooltip',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['placement', 'text'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismTooltipTextComponent = class PrismTooltipTextComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismTooltipTextComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismTooltipTextComponent, isStandalone: true, selector: "prism-tooltip-text", inputs: { text: "text", visible: "visible" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismTooltipTextComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$M,
        inputs: ['text', 'visible']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismTooltipTextComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismTooltipTextComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-tooltip-text',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['text', 'visible'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });
let PrismTransmitterInfoComponent = class PrismTransmitterInfoComponent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismTransmitterInfoComponent, deps: [{ token: i0.ChangeDetectorRef }, { token: i0.ElementRef }, { token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Component }); }
    static { this.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "18.0.3", type: PrismTransmitterInfoComponent, isStandalone: true, selector: "prism-transmitter-info", inputs: { transmitter: "transmitter" }, ngImport: i0, template: '<ng-content></ng-content>', isInline: true, changeDetection: i0.ChangeDetectionStrategy.OnPush }); }
};
PrismTransmitterInfoComponent = __decorate([
    ProxyCmp({
        defineCustomElementFn: defineCustomElement$N,
        inputs: ['transmitter']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], PrismTransmitterInfoComponent);
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: PrismTransmitterInfoComponent, decorators: [{
            type: Component,
            args: [{
                    selector: 'prism-transmitter-info',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
                    inputs: ['transmitter'],
                    standalone: true
                }]
        }], ctorParameters: () => [{ type: i0.ChangeDetectorRef }, { type: i0.ElementRef }, { type: i0.NgZone }] });

class ValueAccessor {
    constructor(el) {
        this.el = el;
        this.onChange = () => { };
        this.onTouched = () => { };
    }
    writeValue(value) {
        this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
    }
    handleChangeEvent(value) {
        if (value !== this.lastValue) {
            this.lastValue = value;
            this.onChange(value);
        }
    }
    _handleBlurEvent() {
        this.onTouched();
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        this.el.nativeElement.disabled = isDisabled;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: ValueAccessor, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Directive }); }
    static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "18.0.3", type: ValueAccessor, host: { listeners: { "focusout": "_handleBlurEvent()" } }, ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: ValueAccessor, decorators: [{
            type: Directive,
            args: [{}]
        }], ctorParameters: () => [{ type: i0.ElementRef }], propDecorators: { _handleBlurEvent: [{
                type: HostListener,
                args: ['focusout']
            }] } });

class TextValueAccessor extends ValueAccessor {
    constructor(el) {
        super(el);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: TextValueAccessor, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Directive }); }
    static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "18.0.3", type: TextValueAccessor, isStandalone: true, selector: "prism-input, prism-menu, prism-radio-group, prism-search-bar, prism-segmented-picker, prism-select", host: { listeners: { "valueChange": "handleChangeEvent($event.target.value)" } }, providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: TextValueAccessor,
                multi: true
            }
        ], usesInheritance: true, ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: TextValueAccessor, decorators: [{
            type: Directive,
            args: [{ standalone: true,
                    /* tslint:disable-next-line:directive-selector */
                    selector: 'prism-input, prism-menu, prism-radio-group, prism-search-bar, prism-segmented-picker, prism-select',
                    host: {
                        '(valueChange)': 'handleChangeEvent($event.target.value)'
                    },
                    providers: [
                        {
                            provide: NG_VALUE_ACCESSOR,
                            useExisting: TextValueAccessor,
                            multi: true
                        }
                    ]
                }]
        }], ctorParameters: () => [{ type: i0.ElementRef }] });

class NumericValueAccessor extends ValueAccessor {
    constructor(el) {
        super(el);
    }
    registerOnChange(fn) {
        super.registerOnChange(value => {
            fn(value === '' ? null : parseFloat(value));
        });
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: NumericValueAccessor, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Directive }); }
    static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "18.0.3", type: NumericValueAccessor, isStandalone: true, selector: "prism-number-input, prism-slider", host: { listeners: { "valueChange": "handleChangeEvent($event.target.value)" } }, providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: NumericValueAccessor,
                multi: true
            }
        ], usesInheritance: true, ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: NumericValueAccessor, decorators: [{
            type: Directive,
            args: [{ standalone: true,
                    /* tslint:disable-next-line:directive-selector */
                    selector: 'prism-number-input, prism-slider',
                    host: {
                        '(valueChange)': 'handleChangeEvent($event.target.value)'
                    },
                    providers: [
                        {
                            provide: NG_VALUE_ACCESSOR,
                            useExisting: NumericValueAccessor,
                            multi: true
                        }
                    ]
                }]
        }], ctorParameters: () => [{ type: i0.ElementRef }] });

class BooleanValueAccessor extends ValueAccessor {
    constructor(el) {
        super(el);
    }
    writeValue(value) {
        this.el.nativeElement.checked = this.lastValue = value == null ? false : value;
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: BooleanValueAccessor, deps: [{ token: i0.ElementRef }], target: i0.ɵɵFactoryTarget.Directive }); }
    static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "18.0.3", type: BooleanValueAccessor, isStandalone: true, selector: "prism-button, prism-icon-button, prism-checkbox, prism-switch, prism-dialog, prism-floating-dialog, prism-snackbar", host: { listeners: { "valueChange": "handleChangeEvent($event.target.checked)", "visibleChange": "handleChangeEvent($event.target.visible)" } }, providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: BooleanValueAccessor,
                multi: true
            }
        ], usesInheritance: true, ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.3", ngImport: i0, type: BooleanValueAccessor, decorators: [{
            type: Directive,
            args: [{ standalone: true,
                    /* tslint:disable-next-line:directive-selector */
                    selector: 'prism-button, prism-icon-button, prism-checkbox, prism-switch, prism-dialog, prism-floating-dialog, prism-snackbar',
                    host: {
                        '(valueChange)': 'handleChangeEvent($event.target.checked)',
                        '(visibleChange)': 'handleChangeEvent($event.target.visible)'
                    },
                    providers: [
                        {
                            provide: NG_VALUE_ACCESSOR,
                            useExisting: BooleanValueAccessor,
                            multi: true
                        }
                    ]
                }]
        }], ctorParameters: () => [{ type: i0.ElementRef }] });

/**
 * Generated bundle index. Do not edit.
 */

export { BooleanValueAccessor, NumericValueAccessor, PrismBatteryBarComponent, PrismBatteryComponent, PrismBatteryGaugeComponent, PrismBatteryIndicatorComponent, PrismBetaIndicatorComponent, PrismBreadcrumbComponent, PrismBreadcrumbItemComponent, PrismButtonComponent, PrismButtonGroupComponent, PrismCalloutComponent, PrismCardComponent, PrismCheckboxComponent, PrismChipComponent, PrismDialogComponent, PrismFloatingDialogBaseComponent, PrismFloatingDialogComponent, PrismIconButtonComponent, PrismIconComponent, PrismInputComponent, PrismLayoutComponent, PrismLinkInfoComponent, PrismListComponent, PrismListItemComponent, PrismMenuBaseComponent, PrismMenuComponent, PrismMenuItemComponent, PrismMuteButtonComponent, PrismNavButtonComponent, PrismNavComponent, PrismNavGroupComponent, PrismNavToggleButtonComponent, PrismNumberInputComponent, PrismProfileImageComponent, PrismProgressDotsComponent, PrismProgressIndicatorComponent, PrismRadioButtonComponent, PrismRadioGroupComponent, PrismRfMeterComponent, PrismSearchBarComponent, PrismSegmentedPickerComponent, PrismSelectComponent, PrismSliderComponent, PrismSnackbarComponent, PrismStatusIndicatorComponent, PrismSwitchComponent, PrismToolbarComponent, PrismTooltipComponent, PrismTooltipTextComponent, PrismTransmitterInfoComponent, TextValueAccessor };

