/* libs/cloud/shared/ui/components/src/lib/footer/footer.component.scss */
:host footer {
  color: var(--prism-color-theme-neutral-pop);
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
}
:host footer #sh-footer-container-div {
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  margin: 10px 10px 5px 80px;
}
:host footer #sh-footer-link-list-div {
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 51px;
  gap: 20px;
}
:host footer #sh-footer-contact-us-button {
  color: var(--prism-color-theme-ink) !important;
  min-width: 100px;
}
:host footer #sh-footer-contact-us-button span {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--prism-color-theme-accent-primary);
}
:host footer #sh-footer-contact-us-button:hover {
  background-color: var(--prism-color-theme-accent-primary) !important;
  color: var(--prism-color-theme-dark-base) !important;
  display: inline-block;
}
:host footer .sh-button-link {
  flex: 0 1 auto;
  color: var(--prism-color-theme-neutral-pop);
  cursor: pointer;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-block;
  min-height: 24px;
  line-height: 20px;
  min-width: initial;
  margin-bottom: 5px;
  letter-spacing: normal;
}
:host footer .sh-button-link {
  margin-right: 20px;
}
:host footer .sh-button-link:hover {
  color: var(--prism-color-theme-surface);
}
:host footer #sh-footer-copyright-div {
  font-size: 13px !important;
  cursor: default !important;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  :host footer #sh-footer-buttons-container {
    display: inline;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }
  :host footer .sh-button-link {
    flex: 1 0 50%;
  }
  :host footer #sh-footer-container-div {
    margin: 10px 0px 5px 0px;
  }
  :host footer #sh-footer-link-list-div {
    gap: 10px;
  }
}
@media (max-width: 959.98px) {
  :host footer {
    margin-bottom: 0 !important;
    max-height: 50px;
  }
  :host footer #sh-footer-container-div {
    margin: 0;
  }
  :host footer #sh-footer-link-list-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  :host footer #sh-footer-copyright-div {
    margin-right: -20px;
  }
  :host footer #sh-footer-menu-container {
    display: flex;
    align-items: center;
  }
  :host footer #sh-footer-menu-container #sh-footer-links-selector {
    background-color: var(--prism-color-theme-dark-base);
    color: var(--prism-color-theme-ink);
    cursor: pointer;
    border: none;
  }
}
::ng-deep .mat-mdc-menu-panel {
  width: 218px !important;
  background-color: var(--prism-color-theme-neutral);
}
::ng-deep #sh-footer-cookie-item * {
  line-height: 1.4 !important;
}
/*# sourceMappingURL=footer.component.css.map */
