import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { PipesModule } from '@shure/cloud/shared/ui/pipes';

@Component({
	selector: 'sh-app-access-not-configured',
	standalone: true,
	imports: [CommonModule, TranslocoModule, PipesModule],
	templateUrl: './app-access-not-configured.component.html',
	styleUrls: ['./app-access-not-configured.component.scss']
})
export class AppAccessNotConfiguredComponent {}
