/* libs/cloud/shared/ui/components/src/lib/app-access-not-configured/app-access-not-configured.component.scss */
#sh-app-access-container-div {
  display: flex;
  height: 84vh;
  gap: 7px;
  margin: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#sh-app-access-container-div #sh-app-access-no-apps-img {
  max-height: 350px;
}
#sh-app-access-container-div #sh-app-access-sub-title-head-div {
  letter-spacing: 0.005em;
  font-size: 21px;
  line-height: 24px;
}
#sh-app-access-container-div #sh-app-access-sub-title-text-div {
  letter-spacing: 0.007em;
  font-size: 17px;
  line-height: 24px;
}
/*# sourceMappingURL=app-access-not-configured.component.css.map */
