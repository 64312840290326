import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'sh-custom-loading-overlay',
	templateUrl: './custom-loading-overlay.component.html',
	styleUrls: ['./custom-loading-overlay.component.scss']
})
export class CustomLoadingOverlayComponent implements ILoadingOverlayAngularComp {
	public params!: ICellRendererParams;

	public agInit(params: ICellRendererParams): void {
		this.params = params;
	}
}
