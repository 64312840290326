/* libs/cloud/shared/ui/components/src/lib/paginator/paginator.component.scss */
:host::ng-deep .mat-mdc-paginator-page-size {
  display: flex;
  align-items: center;
}
:host::ng-deep .mat-mdc-paginator {
  float: left;
  width: 100%;
}
:host::ng-deep .mat-mdc-paginator .mat-mdc-select {
  padding-left: 10px;
}
:host::ng-deep .mat-mdc-paginator .mat-mdc-select-value {
  width: 27px;
}
:host::ng-deep .mat-mdc-paginator-page-size-select {
  width: 60px !important;
}
/*# sourceMappingURL=paginator.component.css.map */
