/* libs/cloud/shared/ui/components/src/lib/carousel/carousel.component.scss */
:host #sh-carousel-image-container-div {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:host #sh-carousel-banner-text-div {
  position: absolute;
  padding-left: 80px;
  color: white;
  font-size: 52px;
  font-weight: 700;
  line-height: 62px;
  max-width: 1263px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
}
:host #sh-carousel-banner-text-div span {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
:host #sh-carousel-banner-text-div #sh-carousel-welcome-text-div {
  font-weight: 400;
  font-size: 51px;
}
@media screen and (max-width: 480px) {
  :host #sh-carousel-welcome-text-div {
    line-height: 40px;
    font-size: 36px !important;
  }
  :host #sh-carousel-banner-text-div {
    padding: 0px 16px;
    margin-top: 25px;
  }
  :host #sh-carousel-image-container-div {
    height: 274px;
  }
  :host #sh-carousel-image-img {
    object-fit: cover;
    object-position: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  :host #sh-carousel-banner-text-div {
    line-height: 100%;
  }
  :host #sh-carousel-banner-text-div #sh-carousel-welcome-text-div {
    font-weight: 400;
    font-size: 40px;
    width: 75%;
  }
}
:host #sh-carousel-image-img {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=carousel.component.css.map */
