/* libs/cloud/shared/ui/components/src/lib/legacy-layout/stacked-layout/layout-container.scss */
:host {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
:host ::ng-deep .mat-drawer {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  bottom: auto;
}
:host ::ng-deep .mat-drawer-container {
  background-color: transparent;
  position: static !important;
}
:host ::ng-deep mat-sidenav {
  width: 260px;
  max-width: 260px;
}
:host ::ng-deep .mat-drawer-inner-container {
  background-color: var(--mat-background-card);
  position: fixed;
}
:host ::ng-deep .mat-drawer-inner-container > .mat-mdc-icon-button {
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 3;
}
:host ::ng-deep .mat-drawer-inner-container .mat-toolbar.mat-toolbar-single-row {
  border-bottom: 1px solid var(--mat-foreground-divider);
}
:host ::ng-deep .mat-drawer.mat-drawer-side,
:host ::ng-deep .mat-drawer.mat-drawer-over {
  border-top: 1px solid var(--mat-foreground-divider);
}
:host ::ng-deep .mat-drawer-inner-container:hover::-webkit-scrollbar {
  display: initial;
}
:host ::ng-deep .mat-drawer-inner-container {
  position: relative;
}
:host ::ng-deep .mat-drawer.mat-drawer-side {
  z-index: 1;
  border-radius: 10px 0px 0px 10px;
}
:host ::ng-deep .mat-sidenav-container {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host ::ng-deep .mat-sidenav-content {
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host ::ng-deep .mat-sidenav-content > div.ab {
  z-index: 2;
  height: 48px;
  top: -48px;
  position: absolute;
  width: 100%;
  background-color: transparent;
}
:host ::ng-deep .mat-toolbar.mat-toolbar-single-row.sh-layout-container-sidenav-left {
  padding-right: 48px;
}
:host ::ng-deep .mat-toolbar.mat-toolbar-single-row.sh-layout-container-sidenav-left > .mat-mdc-icon-button {
  right: 0;
  position: absolute;
}
:host ::ng-deep .mat-toolbar.mat-toolbar-single-row.sh-layout-container-sidenav-right {
  padding-left: 3px;
  background-color: var(--mat-background-card);
}
:host ::ng-deep .mat-toolbar.mat-toolbar-single-row.sh-layout-container-sidenav-right > button {
  color: white;
}
:host ::ng-deep .mat-toolbar.mat-toolbar-single-row.sh-layout-container-sidenav-right > span {
  font-size: 18px;
}
:host.sh-layout-container-no-actionbar ::ng-deep .sh-sidenav-button-toggle {
  height: 100%;
  top: 0;
}
:host ::ng-deep .sh-sidenav-button-toggle {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  height: calc(100% - 48px);
  width: 0;
  left: 0;
  top: 48px;
  bottom: 0;
}
:host ::ng-deep .sh-sidenav-button-toggle > .sh-slide-toggle-icon {
  background-color: var(--mat-background-card);
  top: 90px;
  position: relative;
  transform: translateY(-50%);
  height: 150px;
  width: 32px;
  border-radius: 0 8px 8px 0;
}
:host ::ng-deep .sh-sidenav-button-toggle > .sh-slide-toggle-icon > .mat-icon {
  border-style: none;
  border-width: 0;
  display: inline-block;
  padding: 3px;
  font-size: 25px;
  top: 25px;
  position: relative;
  transform: translate(-50%, -50%);
  left: 50%;
}
:host ::ng-deep .sh-sidenav-button-toggle > .sh-slide-toggle-icon > .mat-divider {
  top: 15%;
  position: relative;
}
:host ::ng-deep .sh-sidenav-button-toggle > .sh-slide-toggle-icon > span {
  transform-origin: 0 0;
  transform: rotate(270deg);
  white-space: nowrap;
  position: absolute;
  top: 90%;
  left: 15%;
}
:host ::ng-deep .sh-sidenav-button-toggle-opened {
  z-index: 0;
}
:host ::ng-deep .sh-sidenav-button-toggle.sh-sidetoggle-right {
  left: auto;
  right: 32px;
}
:host ::ng-deep .sh-sidenav-button-toggle.sh-sidetoggle-right > .sh-slide-toggle-icon {
  border-radius: 8px 0 0 8px;
}
/*# sourceMappingURL=layout-container.css.map */
