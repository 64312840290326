export enum DeviceLicenseState {
	Unlicensed = 'Unlicensed',
	ActivationConfirmed = 'ActivationConfirmed',
	GracePeriod = 'GracePeriod',
	WarningPeriod = 'WarningPeriod',
	Expired = 'Expired',
	CommunicationIssue = 'CommunicationIssue',
	Insufficient = 'Insufficient',
	ClockSetting = 'ClockSetting',
	InvalidId = 'InvalidId',
	ActivationPending = 'ActivationPending',
	DeactivationPending = 'DeactivationPending',
	UnknownIssue = 'UnknownIssue'
}

export enum DeviceLicenseType {
	Paid = 'Paid',
	Demo = 'Demo',
	Trial = 'Trial'
}

export interface DeviceLicenseWarningDetails {
	graceExpiresIn: number;
	channelCount: number;
}
export interface DeviceLicense<T> {
	state: DeviceLicenseState;
	details: T;
}
