/* libs/cloud/shared/feature-request/feature-request/src/lib/feature-request-main.component.scss */
:host mat-form-field {
  width: 100%;
  margin-bottom: 12px;
}
:host mat-mdc-dialog-content {
  margin: 0px -24px 6px -24px;
}
:host textarea {
  resize: none;
}
:host .sh-feature-request-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 24px 0;
}
:host .sh-feature-request-dialog-buttons .mat-mdc-button {
  border-radius: 1rem !important;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
}
:host .sh-feature-request-dialog-buttons .mat-mdc-button:hover {
  background-color: var(--prism-color-theme-outline);
  color: var(--prism-color-theme-base-contrast);
}
:host .sh-feature-request-dialog-buttons .mat-mdc-button-disabled {
  color: var(--prism-color-theme-disabled);
}
:host #sh-feature-request-dialog-contact-info-div {
  color: var(--prism-color-theme-base-contrast) !important;
  font-size: 13px !important;
  line-height: 16px !important;
}
:host mat-label {
  color: var(--prism-color-theme-base-contrast) !important;
}
:host #sh-feature-request-dialog-description-div {
  padding: 0 24px;
  font-size: 17px !important;
  line-height: 28px !important;
}
:host #sh-dialog-box-feature-request-contact-info-div {
  font-size: 14px;
  line-height: 20px;
}
:host .mat-form-field-disabled mat-label {
  color: var(--prism-color-theme-disabled-contrast) !important;
}
:host ::ng-deep .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
}
:host ::ng-deep .mat-mdc-dialog-surface.mdc-dialog__surface {
  background-color: var(--prism-color-theme-surface) !important;
}
:host ::ng-deep .mat-mdc-input-element {
  color: var(--prism-color-theme-base-contrast) !important;
}
::ng-deep .mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 0 !important;
}
/*# sourceMappingURL=feature-request-main.component.css.map */
