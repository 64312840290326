import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
	StackedLayoutComponent,
	StackedLayoutTopBannerComponent,
	StackedLayoutAppBarComponent,
	StackedLayoutContentComponent,
	StackedLayoutFooterComponent,
	LayoutContainerComponent,
	LayoutActionBarComponent,
	LayoutContentComponent,
	LayoutContentLeftSidenavComponent,
	LayoutContentRightSidenavComponent
} from './stacked-layout.component';

@NgModule({
	imports: [
		CommonModule,
		MatToolbarModule,
		MatSidenavModule,
		MatIconModule,
		MatTabsModule,
		MatButtonModule,
		MatDividerModule
	],
	declarations: [
		StackedLayoutComponent,
		StackedLayoutTopBannerComponent,
		StackedLayoutAppBarComponent,
		StackedLayoutContentComponent,
		StackedLayoutFooterComponent,
		LayoutContainerComponent,
		LayoutActionBarComponent,
		LayoutContentComponent,
		LayoutContentLeftSidenavComponent,
		LayoutContentRightSidenavComponent
	],
	exports: [
		StackedLayoutComponent,
		StackedLayoutTopBannerComponent,
		StackedLayoutAppBarComponent,
		StackedLayoutContentComponent,
		StackedLayoutFooterComponent,
		LayoutContainerComponent,
		LayoutActionBarComponent,
		LayoutContentComponent,
		LayoutContentLeftSidenavComponent,
		LayoutContentRightSidenavComponent
	]
})
export class StackedLayoutModule {}
