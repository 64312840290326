import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export interface InfoDialogData {
	title: string;
	body: string[];
	okLabel: string;
	showWarningIcon?: boolean;
}

@Component({
	selector: 'sh-info-dialog',
	templateUrl: './info-dialog.component.html',
	styleUrls: ['./info-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, FlexLayoutModule, MatDialogModule, MatButtonModule, MatIconModule]
})
export class InfoDialogComponent {
	constructor(
		private readonly dialogRef: MatDialogRef<InfoDialogComponent, boolean>,
		@Inject(MAT_DIALOG_DATA) public readonly data: InfoDialogData
	) {}

	public close(): void {
		this.dialogRef.close();
	}
}
