import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@jsverse/transloco';

import { PipesModule } from '@shure/cloud/shared/ui/pipes';

import { CarouselComponent } from './carousel.component';

@NgModule({
	declarations: [CarouselComponent],
	exports: [CarouselComponent],
	imports: [CommonModule, FlexLayoutModule, TranslocoModule, PipesModule]
})
export class CarouselModule {}
