/* libs/cloud/shared/ui/components/src/lib/cell-renderers/endpoint-name-renderer/endpoint-name-renderer.component.scss */
:host {
  width: 100%;
  height: 100%;
}
:host ::ng-deep .mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 32px;
  padding: 0;
}
:host ::ng-deep .mat-mdc-icon-button:not([disabled]) {
  color: var(--prism-color-theme-ink) !important;
}
:host #sh-endpoint-name-renderer-cell-div {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
:host #sh-endpoint-name-renderer-device-state {
  margin-right: 10px;
  width: 5px;
  align-self: stretch;
}
:host #sh-endpoint-name-renderer-device-image {
  min-width: 30px;
  margin: 0 10px 0 2px;
}
:host #sh-endpoint-name-renderer-container-span {
  margin-right: 8px;
  align-items: center;
  display: flex;
}
:host #sh-endpoint-name-renderer-text-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host #sh-endpoint-name-renderer-text-span {
  word-break: break-all;
  text-wrap: pretty;
  line-height: 15px;
  max-height: 32px;
  overflow: hidden;
  margin-left: 5px;
}
:host sh-id-button {
  align-self: center;
}
/*# sourceMappingURL=endpoint-name-renderer.component.css.map */
