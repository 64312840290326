export interface ChannelPcPeripheral {
	id: string;
	name: string;
	status: ChannelPcPeripheralStatus;
}

export enum ChannelPcPeripheralStatus {
	Available = 'AVAILABLE',
	Begin = 'BEGIN',
	End = 'END',
	InProgress = 'IN_PROGRESS',
	None = 'NONE',
	Unavailable = 'UNAVAILABLE'
}
