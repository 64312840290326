import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BreadcrumbsComponent } from './breadcrumbs.component';

@NgModule({
	declarations: [BreadcrumbsComponent],
	exports: [BreadcrumbsComponent],
	imports: [CommonModule, FlexLayoutModule, MatIconModule, MatToolbarModule, MatTooltipModule]
})
export class BreadcrumbsModule {}
