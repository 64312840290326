<div id="sh-info-icon-main">
	<div id="sh-info-icon-container">
		<div id="sh-info-icon-div" *ngIf="params.customData">
			<span>{{ params.customData }}</span>
		</div>
		<div id="sh-info-icon-info-mat-icon-div">
			<mat-icon
				*ngIf="params.customToolTip"
				id="sh-info-icon-info-mat-icon"
				(click)="$event.stopPropagation()"
				fontSet="prism-mat"
				fontIcon="info_outline"
				matTooltipClass="custom-tooltip sh-info-icon-status-tooltip"
				matTooltip="{{ params.customToolTip }}"
				matTooltipPosition="above"
			></mat-icon>
		</div>
	</div>
</div>
