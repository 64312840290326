/* libs/cloud/shared/ui/components/src/lib/loader/loader.component.scss */
.sh-loader {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.sh-loader > * {
  height: 1rem;
  width: 1rem;
  background-color: #fff;
  border-radius: 50%;
  animation-iteration-count: infinite;
  animation-duration: 3.316s;
  animation-fill-mode: both;
}
@keyframes dot-1-anim {
  0% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #b2ff33;
  }
  5% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  20% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  30% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #b2ff33;
  }
  31% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #fff;
  }
  35% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  60% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  61% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
  }
  65% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  80% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  90% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #fff;
  }
}
@keyframes dot-2-anim {
  0% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #fff;
  }
  5% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  20% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  30% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #fff;
  }
  31% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #b2ff33;
  }
  35% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  60% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #b2ff33;
  }
  61% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #fff;
  }
  65% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  80% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  90% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #fff;
  }
}
@keyframes dot-3-anim {
  0% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #fff;
  }
  5% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  20% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  30% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  31% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
  }
  35% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  60% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #fff;
  }
  61% {
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;
    background-color: #b2ff33;
  }
  65% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  80% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  90% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 0;
    background-color: #b2ff33;
  }
}
.sh-loader :nth-child(1) {
  animation-name: dot-1-anim;
}
.sh-loader :nth-child(2) {
  animation-delay: 0.165s;
  animation-name: dot-2-anim;
}
.sh-loader :nth-child(3) {
  animation-delay: 0.33s;
  animation-name: dot-3-anim;
}
/*# sourceMappingURL=loader.component.css.map */
