<sh-ag-grid-responsive-wrapper
	id="sh-ag-grid-responsive-wrapper"
	[gridApi]="gridApi"
	[minNoRowsHeight]="minNoRowsHeight"
	[minLoadingHeight]="minLoadingHeight"
	[minHeight]="minHeight"
	class="ag-theme-alpine-dark"
>
	<ag-grid-angular id="sh-ag-grid-table" [rowData]="rowData" [columnDefs]="columnDefs" [gridOptions]="gridOptions">
	</ag-grid-angular>
</sh-ag-grid-responsive-wrapper>
