/* libs/cloud/shared/ui/components/src/lib/secondary-nav/secondary-nav.component.scss */
:host #sh-secondary-nav-unavailable-container-div {
  display: flex;
  height: 84vh;
  gap: 7px;
  margin: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:host #sh-secondary-nav-unavailable-container-div #sh-secondary-nav-unavailable-img {
  max-height: 350px;
  height: 40vh;
}
:host #sh-secondary-nav-unavailable-container-div #sh-secondary-nav-unavailable-sub-title-head-div {
  letter-spacing: 0.005em;
  font-size: 21px;
  line-height: 24px;
}
:host #sh-secondary-nav-unavailable-container-div #sh-secondary-nav-unavailable-sub-title-text-div {
  letter-spacing: 0.007em;
  font-size: 17px;
  line-height: 24px;
  max-width: 20.313rem;
  text-align: center;
}
:host #sh-secondary-nav-unavailable-container-div #sh-secondary-nav-unavailable-button {
  padding: 0.5rem 1rem;
  background-color: var(--prism-color-theme-neutral-dim);
  margin-top: 10px;
  font-weight: normal;
}
@media (max-width: 932px) and (orientation: landscape) {
  :host #sh-secondary-nav-unavailable-container-div #sh-secondary-nav-unavailable-img {
    height: 30vh;
  }
}
:host #sh-secondary-nav-header {
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  padding-bottom: 10px;
}
:host .mat-menu-item-active {
  background-color: var(--prism-color-theme-surface-variant) !important;
}
:host .mat-mdc-nav-list .mat-mdc-list-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
}
:host .mat-mdc-nav-list .mat-mdc-list-item mat-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  margin-right: 8px;
}
:host .mat-mdc-nav-list .mat-list-text {
  display: block;
  text-align: center;
  white-space: normal;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.104px;
}
:host #sh-secondary-nav-content-div {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  height: 100% !important;
}
:host #sh-secondary-nav-content-div #sh-secondary-nav-footer-content-div {
  margin-top: 15px;
}
:host #sh-secondary-nav-right-content-div {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  overflow: auto;
}
:host ::ng-deep .breadcrumb-container {
  margin-top: -30px;
  margin-left: 15px;
  color: var(--prism-color-theme-ink);
}
:host ::ng-deep .breadcrumb-container a.breadcrumb:hover:not(.lastCrumb) {
  border-bottom: 2px solid var(--prism-color-theme-brand-ignition-green) !important;
}
:host ::ng-deep .breadcrumb-container a.breadcrumb.lastCrumb {
  opacity: 0.4 !important;
}
:host ::ng-deep .mat-drawer-container {
  height: 100%;
}
:host ::ng-deep .mat-drawer-container .mat-drawer-content sh-secnav-layout-content {
  height: 100%;
}
:host ::ng-deep .mat-drawer {
  padding: 24px 16px 24px 16px;
  gap: 8px;
  opacity: 0px;
  width: 15%;
  background-color: var(--prism-color-theme-base-variant) !important;
}
/*# sourceMappingURL=secondary-nav.component.css.map */
