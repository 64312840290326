/* libs/cloud/shared/ui/components/src/lib/html-snackbar/html-snackbar.component.scss */
:host {
  display: flex;
}
:host .sh-snackbar-message {
  font-size: 16px;
  font-weight: 350;
}
:host .sh-snackbar-dismiss-button {
  font-size: 16px;
}
::ng-deep mat-snack-bar-container {
  border-radius: 3px;
}
::ng-deep .mdc-snackbar__surface {
  background-color: inherit !important;
}
/*# sourceMappingURL=html-snackbar.component.css.map */
