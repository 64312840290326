/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountInfoDto } from '../models/account-info-dto';
import { AccountInfoResponse } from '../models/account-info-response';
import { AddOwnerDto } from '../models/add-owner-dto';
import { AddOwnerResponse } from '../models/add-owner-response';
import { AssignRoleDto } from '../models/assign-role-dto';
import { ChangePasswordDto } from '../models/change-password-dto';
import { ChangeUserRoleDto } from '../models/change-user-role-dto';
import { GetAllUsersTermsConditionsResponse } from '../models/get-all-users-terms-conditions-response';
import { InviteUserDto } from '../models/invite-user-dto';
import { LeaveOrganizationResponse } from '../models/leave-organization-response';
import { RemoveAccessDto } from '../models/remove-access-dto';
import { ResendInviteDto } from '../models/resend-invite-dto';
import { RevokeInviteDto } from '../models/revoke-invite-dto';
import { UpdateAccountInfoDto } from '../models/update-account-info-dto';
import { UpdateAccountInfoResponse } from '../models/update-account-info-response';
import { UpdateTermsAndConditionsDto } from '../models/update-terms-and-conditions-dto';
import { UpdateTermsAndConditionsResponse } from '../models/update-terms-and-conditions-response';
import { UserAccessibleAppsResponse } from '../models/user-accessible-apps-response';
import { UsersDto } from '../models/users-dto';

@Injectable({
  providedIn: 'root',
})
export class ApiUsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUsers
   */
  static readonly GetUsersPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: {
    pageSize?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.GetUsersPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: {
    pageSize?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.getUsers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createUser
   */
  static readonly CreateUserPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: {
    context?: HttpContext
    body: UsersDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.CreateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: {
    context?: HttpContext
    body: UsersDto
  }
): Observable<void> {

    return this.createUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTermsAndConditions
   */
  static readonly GetTermsAndConditionsPath = '/api/v2/terms-and-conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTermsAndConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions$Response(params?: {
    pageSize?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetAllUsersTermsConditionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.GetTermsAndConditionsPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAllUsersTermsConditionsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTermsAndConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions(params?: {
    pageSize?: number;
    page?: number;
    context?: HttpContext
  }
): Observable<GetAllUsersTermsConditionsResponse> {

    return this.getTermsAndConditions$Response(params).pipe(
      map((r: StrictHttpResponse<GetAllUsersTermsConditionsResponse>) => r.body as GetAllUsersTermsConditionsResponse)
    );
  }

  /**
   * Path part for operation updateTermsAndConditions
   */
  static readonly UpdateTermsAndConditionsPath = '/api/v2/terms-and-conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTermsAndConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTermsAndConditions$Response(params: {
    context?: HttpContext
    body: UpdateTermsAndConditionsDto
  }
): Observable<StrictHttpResponse<UpdateTermsAndConditionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.UpdateTermsAndConditionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateTermsAndConditionsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTermsAndConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTermsAndConditions(params: {
    context?: HttpContext
    body: UpdateTermsAndConditionsDto
  }
): Observable<UpdateTermsAndConditionsResponse> {

    return this.updateTermsAndConditions$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateTermsAndConditionsResponse>) => r.body as UpdateTermsAndConditionsResponse)
    );
  }

  /**
   * Path part for operation rejectInvite
   */
  static readonly RejectInvitePath = '/api/v2/rejectInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectInvite$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.RejectInvitePath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rejectInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectInvite(params: {
    token: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.rejectInvite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation inviteUser
   */
  static readonly InviteUserPath = '/api/v2/inviteUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser$Response(params: {
    context?: HttpContext
    body: InviteUserDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.InviteUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser(params: {
    context?: HttpContext
    body: InviteUserDto
  }
): Observable<void> {

    return this.inviteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation assignRole
   */
  static readonly AssignRolePath = '/api/v1/assignRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRole$Response(params: {
    context?: HttpContext
    body: AssignRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.AssignRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRole(params: {
    context?: HttpContext
    body: AssignRoleDto
  }
): Observable<void> {

    return this.assignRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAccountInfo
   */
  static readonly GetAccountInfoPath = '/api/v1/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountInfo$Response(params?: {
    projections?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.GetAccountInfoPath, 'get');
    if (params) {
      rb.query('projections', params.projections, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccountInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountInfo(params?: {
    projections?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getAccountInfo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountInfo
   */
  static readonly AccountInfoPath = '/api/v1/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountInfo$Response(params: {
    context?: HttpContext
    body: AccountInfoDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.AccountInfoPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountInfo(params: {
    context?: HttpContext
    body: AccountInfoDto
  }
): Observable<void> {

    return this.accountInfo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation acceptInvite
   */
  static readonly AcceptInvitePath = '/api/v2/acceptInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvite$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.AcceptInvitePath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvite(params: {
    token: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.acceptInvite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/api/v2/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUserDetails
   */
  static readonly UpdateUserDetailsPath = '/api/v2/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDetails$Response(params: {
    userId: string;
    context?: HttpContext
    body: UsersDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.UpdateUserDetailsPath, 'patch');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDetails(params: {
    userId: string;
    context?: HttpContext
    body: UsersDto
  }
): Observable<void> {

    return this.updateUserDetails$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation revokeInvite
   */
  static readonly RevokeInvitePath = '/api/v2/revokeInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeInvite$Response(params: {
    context?: HttpContext
    body: RevokeInviteDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.RevokeInvitePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revokeInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeInvite(params: {
    context?: HttpContext
    body: RevokeInviteDto
  }
): Observable<void> {

    return this.revokeInvite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation revokeAccess
   */
  static readonly RevokeAccessPath = '/api/v2/revokeAccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeAccess$Response(params: {
    context?: HttpContext
    body: RemoveAccessDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.RevokeAccessPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revokeAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeAccess(params: {
    context?: HttpContext
    body: RemoveAccessDto
  }
): Observable<void> {

    return this.revokeAccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation resendInvite
   */
  static readonly ResendInvitePath = '/api/v2/resendInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendInvite$Response(params: {
    context?: HttpContext
    body: ResendInviteDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.ResendInvitePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resendInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendInvite(params: {
    context?: HttpContext
    body: ResendInviteDto
  }
): Observable<void> {

    return this.resendInvite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation changeRole
   */
  static readonly ChangeRolePath = '/api/v2/changeRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRole$Response(params: {
    context?: HttpContext
    body: ChangeUserRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.ChangeRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRole(params: {
    context?: HttpContext
    body: ChangeUserRoleDto
  }
): Observable<void> {

    return this.changeRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation roleCount
   */
  static readonly RoleCountPath = '/api/v2/role-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleCount$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.RoleCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleCount(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.roleCount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userInfo
   */
  static readonly UserInfoPath = '/api/v2/contactdetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userInfo$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.UserInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userInfo(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.userInfo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation changePassword
   */
  static readonly ChangePasswordPath = '/api/v2/changepassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: {
    context?: HttpContext
    body: ChangePasswordDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.ChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: {
    context?: HttpContext
    body: ChangePasswordDto
  }
): Observable<void> {

    return this.changePassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addOwner
   */
  static readonly AddOwnerPath = '/api/v2/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOwner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOwner$Response(params: {
    context?: HttpContext
    body: AddOwnerDto
  }
): Observable<StrictHttpResponse<AddOwnerResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.AddOwnerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddOwnerResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOwner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOwner(params: {
    context?: HttpContext
    body: AddOwnerDto
  }
): Observable<AddOwnerResponse> {

    return this.addOwner$Response(params).pipe(
      map((r: StrictHttpResponse<AddOwnerResponse>) => r.body as AddOwnerResponse)
    );
  }

  /**
   * Path part for operation getSessionAccountInfo
   */
  static readonly GetSessionAccountInfoPath = '/api/v2/session/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSessionAccountInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionAccountInfo$Response(params?: {
    projections?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.GetSessionAccountInfoPath, 'get');
    if (params) {
      rb.query('projections', params.projections, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSessionAccountInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionAccountInfo(params?: {
    projections?: string;
    context?: HttpContext
  }
): Observable<AccountInfoResponse> {

    return this.getSessionAccountInfo$Response(params).pipe(
      map((r: StrictHttpResponse<AccountInfoResponse>) => r.body as AccountInfoResponse)
    );
  }

  /**
   * Path part for operation updateSessionAccountInfo
   */
  static readonly UpdateSessionAccountInfoPath = '/api/v2/session/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSessionAccountInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSessionAccountInfo$Response(params: {
    context?: HttpContext
    body: UpdateAccountInfoDto
  }
): Observable<StrictHttpResponse<UpdateAccountInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.UpdateSessionAccountInfoPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateAccountInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSessionAccountInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSessionAccountInfo(params: {
    context?: HttpContext
    body: UpdateAccountInfoDto
  }
): Observable<UpdateAccountInfoResponse> {

    return this.updateSessionAccountInfo$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateAccountInfoResponse>) => r.body as UpdateAccountInfoResponse)
    );
  }

  /**
   * Path part for operation deleteSessionMembership
   */
  static readonly DeleteSessionMembershipPath = '/api/v1/session/membership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSessionMembership()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSessionMembership$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LeaveOrganizationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.DeleteSessionMembershipPath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LeaveOrganizationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSessionMembership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSessionMembership(params?: {
    context?: HttpContext
  }
): Observable<LeaveOrganizationResponse> {

    return this.deleteSessionMembership$Response(params).pipe(
      map((r: StrictHttpResponse<LeaveOrganizationResponse>) => r.body as LeaveOrganizationResponse)
    );
  }

  /**
   * Path part for operation getUserAccessibleApps
   */
  static readonly GetUserAccessibleAppsPath = '/api/v2/apps/access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAccessibleApps()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccessibleApps$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserAccessibleAppsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ApiUsersService.GetUserAccessibleAppsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAccessibleAppsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAccessibleApps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccessibleApps(params?: {
    context?: HttpContext
  }
): Observable<UserAccessibleAppsResponse> {

    return this.getUserAccessibleApps$Response(params).pipe(
      map((r: StrictHttpResponse<UserAccessibleAppsResponse>) => r.body as UserAccessibleAppsResponse)
    );
  }

}
