/* libs/cloud/shared/ui/components/src/lib/dialogs/progressive-profile/progressive-profile-dialog.component.scss */
:host mat-form-field {
  width: 100%;
}
:host mat-mdc-dialog-content {
  margin: 0px -24px 6px -24px;
}
:host #sh-prog-profile-dialog-title-h1::before {
  display: block;
  height: 25px;
}
:host .sh-button-list {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 24px 0;
}
:host .sh-button-list .mat-mdc-button {
  border-radius: 1rem !important;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
}
:host .sh-button-list .mat-mdc-button:hover {
  background-color: var(--prism-color-theme-window-film-hover);
  color: var(--prism-color-theme-ink);
}
:host .sh-prog-profile-dialog-checkbox-div {
  padding: 15px 0px;
}
:host mat-label {
  color: var(--prism-color-theme-neutral-pop-higher) !important;
}
:host ::ng-deep .mat-mdc-select-arrow {
  color: var(--prism-color-theme-base-contrast) !important;
}
:host #sh-prog-profile-dialog-interests-mat-label,
:host #sh-prog-profile-dialog-relationToShure-mat-label {
  position: relative;
  padding-bottom: 10px;
  display: block;
}
:host .mat-mdc-input-element {
  color: var(--prism-color-theme-ink) !important;
}
:host .mat-form-field-disabled mat-label {
  color: var(--prism-color-theme-disabled-contrast) !important;
}
:host #sh-progressive-dialog-tc-link {
  color: var(--prism-color-theme-base-contrast) !important;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
:host #sh-progressive-dialog-tc-link::after {
  display: block;
  border-bottom: 0.13rem solid var(--prism-color-theme-accent-primary);
  content: " ";
  width: 98%;
  left: 0px;
  position: absolute;
  bottom: 0px;
}
:host #sh-progressive-dialog-tc-link:hover {
  background-color: var(--prism-color-theme-accent-primary);
  color: var(--prism-color-theme-base) !important;
  display: inline-block;
}
@media (min-width: 481px) and (max-width: 932px) and (orientation: landscape) {
  #sh-prog-profile-dialog-box-title-h1 {
    margin: 0px;
  }
  mat-form-field {
    margin-bottom: 0px !important;
  }
  #sh-prog-profile-dialog-box-content {
    max-height: 30vh;
    padding-bottom: 0px;
  }
  ::ng-deep .sh-mat-select-option {
    min-height: 45px !important;
  }
}
/*# sourceMappingURL=progressive-profile-dialog.component.css.map */
