export enum DeviceMuteControlFunction {
	Local = 'LOCAL',
	LogicOut = 'LOGIC_OUT',
	Disabled = 'DISABLED'
}

export enum DeviceMuteControlMode {
	Toggle = 'TOGGLE',
	PushToMute = 'PUSH_TO_MUTE',
	PushToTalk = 'PUSH_TO_TALK'
}

export enum DeviceDefaultToggleState {
	Unmuted = 'UNMUTED',
	Muted = 'MUTED'
}
