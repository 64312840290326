import { CommonModule } from '@angular/common';
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	Renderer2
} from '@angular/core';

import { FooterModule } from '../footer';

@Component({
	selector: 'sh-feature-container',
	standalone: true,
	imports: [CommonModule, FooterModule],
	templateUrl: './feature-container.component.html',
	styleUrls: ['./feature-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureContainerComponent implements AfterContentInit {
	private thisElement?: HTMLElement;
	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2
	) {}

	//
	// The logic in this component is to resize the height of this feature
	// component to account for browsers on phones (or others?) that include
	// an extra header or footer that affects the usable height. This means
	// we don't have 100vh for the page. The window.innerHeight element
	// provides a height that is usable.
	//

	@HostListener('window:resize')
	public onResize(): void {
		this.setUsableHeight();
	}

	public ngAfterContentInit(): void {
		this.thisElement = this.elementRef.nativeElement.querySelector('.feature-container');
		this.setUsableHeight();
	}

	public setUsableHeight(): void {
		if (window.innerHeight && this.thisElement) {
			const toolBarHeight = document.querySelector('sh-header-toolbar');
			const usableHeight = window.innerHeight - (toolBarHeight ? toolBarHeight?.clientHeight : 0);
			this.renderer.setStyle(this.thisElement, 'height', `${usableHeight}px`);
		}
	}
}
