/* libs/cloud/shared/ui/components/src/lib/legacy-layout/sidenav-layout/sidenav-layout.component.scss */
:host {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host .sh-complex-layout-container {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host ::ng-deep .mat-drawer-inner-container {
  overflow-y: overlay !important;
}
:host ::ng-deep .mat-drawer-inner-container > .mat-mdc-icon-button {
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 3;
}
:host ::ng-deep .mat-drawer-inner-container:hover::-webkit-scrollbar {
  display: initial;
}
:host ::ng-deep .mat-drawer-inner-container {
  position: relative;
}
:host ::ng-deep .mat-drawer.mat-drawer-side {
  z-index: 1;
  border-top: none;
}
:host ::ng-deep .mat-sidenav-container {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
:host ::ng-deep .mat-sidenav-content {
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}
@media only screen and (max-width: 960px) {
  :host ::ng-deep .sh-sidenav-layout-secnav-toggle .mat-drawer-backdrop {
    z-index: 1;
  }
}
/*# sourceMappingURL=sidenav-layout.component.css.map */
