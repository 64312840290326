import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';

import { InactivityService } from './services/inactivity.service';
import { InactivityDialogComponent } from './ui/inactivity-dialog.component';

@NgModule({
	imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatDialogModule, TranslocoModule],
	declarations: [InactivityDialogComponent]
})
export class InactivityModule {
	public static forRoot(): ModuleWithProviders<InactivityModule> {
		return {
			ngModule: InactivityModule,
			providers: [InactivityService]
		};
	}
}
