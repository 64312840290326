/* libs/cloud/shared/ui/components/src/lib/breadcrumbs/breadcrumbs.component.scss */
:host mat-toolbar {
  background-color: transparent;
  padding-left: 8px;
}
:host mat-icon {
  opacity: 0.3;
  padding: 0 17px;
}
:host .breadcrumb-container {
  overflow-x: visible;
  text-overflow: ellipsis;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
}
:host .breadcrumb-container a.breadcrumb {
  text-decoration-line: none;
  font-family: sans-serif;
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host .breadcrumb-container a.breadcrumb.lastCrumb {
  font-style: italic;
  opacity: 0.8;
  padding-right: 5px;
}
:host .breadcrumb-container a.breadcrumb.lastCrumb:hover {
  border-bottom: none;
  cursor: auto;
  padding-top: 0px;
}
:host .breadcrumb-container a.breadcrumb:hover {
  border-bottom: 2px solid #017bc3;
  cursor: pointer;
  padding-top: 2px;
}
@media (max-width: 480px) {
  :host {
    width: 100%;
  }
  :host mat-icon,
  :host mat-toolbar {
    padding: 0 15px;
  }
  :host .hidefirstcrumb {
    display: none;
  }
  :host .hidefirstcrumb + mat-icon {
    padding-left: 0px;
  }
}
@media (min-width: 481px) and (max-width: 932px) and (orientation: landscape) {
  :host mat-icon {
    padding: 0 15px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  :host mat-icon {
    padding: 0 15px;
  }
}
/*# sourceMappingURL=breadcrumbs.component.css.map */
