import { NgModule } from '@angular/core';

import { DeviceImageProvider } from '@shure/shared/angular/ui/components/device-image';

import { DeviceImageComponent } from './device-image.component';
import { DeviceImageModule } from './device-image.module';

@NgModule({
	providers: [
		{
			provide: DeviceImageProvider,
			useValue: <DeviceImageProvider>{
				getImage: (model) => ({
					live: `${model}-live.svg`,
					virtual: `${model}-virtual.svg`
				})
			}
		}
	],
	imports: [DeviceImageModule],
	exports: [DeviceImageComponent]
})
export class DeviceImageTestingModule {}
