// eslint-disable-next-line max-classes-per-file
import { trigger } from '@angular/animations';
import {
	Component,
	Input,
	HostBinding,
	ContentChild,
	ChangeDetectionStrategy,
	AfterContentInit,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	Directive
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject } from 'rxjs';

import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';

@Component({
	selector: 'sh-stacked-layout',
	templateUrl: './stacked-layout.component.html',
	styleUrls: ['./stacked-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedLayoutComponent {}

@Component({
	selector: 'sh-stacked-layout-topbanner',
	template: '<ng-content></ng-content>',
	styleUrls: ['../shared/layout-topbanner.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedLayoutTopBannerComponent {}

@Component({
	selector: 'sh-stacked-layout-appbar',
	template: '<ng-content></ng-content>',
	styleUrls: ['./stacked-layout-appbar.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedLayoutAppBarComponent {}

@Component({
	selector: 'sh-stacked-layout-content',
	template: '<ng-content></ng-content>',
	styleUrls: ['./stacked-layout-content.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedLayoutContentComponent {}

@Component({
	selector: 'sh-stacked-layout-footer',
	templateUrl: '../shared/layout-footer.html',
	styleUrls: ['../shared/layout-footer.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedLayoutFooterComponent {
	@Input() public logoPath!: string;
	@HostBinding('class.mat-elevation-z4') public elevationCssClass = true;
}

@Component({
	selector: 'sh-layout-actionbar',
	styleUrls: ['./sh-layout-actionbar.scss'],
	template: `
		<mat-toolbar>
			<ng-content></ng-content>
		</mat-toolbar>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutActionBarComponent {}

@Component({
	selector: 'sh-layout-content',
	template: ' <ng-content></ng-content> ',
	styleUrls: ['layout-content.scss']
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutContentComponent {
	@Input(/*'sh-layout-content-padding'*/) public set containerPadding(padding: unknown) {
		//
	}
}

export type SidenavMode = 'over' | 'side';

@Directive()
export class LayoutContentSidenavComponent {
	@Input() public set opened(value: boolean) {
		if (this.opened === value) {
			return;
		}
		this.opened$.next(value);
	}
	public get opened(): boolean {
		return this.opened$.getValue();
	}

	@Input() public set mode(value: SidenavMode) {
		if (this.mode === value) {
			return;
		}
		this.mode$.next(value);
	}
	public get mode(): SidenavMode {
		return this.mode$.getValue();
	}

	@Input() public title = '';

	@Output() public openedChange = new EventEmitter<boolean>();

	public opened$ = new BehaviorSubject<boolean>(true);
	public mode$ = new BehaviorSubject<SidenavMode>('side');
}

@Component({
	selector: 'sh-layout-content-left-sidenav',
	template: '<ng-content></ng-content>'
})
export class LayoutContentLeftSidenavComponent extends LayoutContentSidenavComponent {
	constructor() {
		super();
	}
}

@Component({
	selector: 'sh-layout-content-right-sidenav',
	template: '<ng-content></ng-content>'
})
export class LayoutContentRightSidenavComponent extends LayoutContentSidenavComponent {
	constructor() {
		super();
	}
}

@Component({
	// Fix memory leak issue with sidenav: https://github.com/angular/components/issues/8862
	animations: [trigger('noop', [])],
	selector: 'sh-layout-container',
	template: `
		<div>
			<ng-content select="sh-layout-actionbar"></ng-content>
		</div>
		<!-- Left sidenav toggler -->
		<div
			class="sh-sidenav-button-toggle sh-sidetoggle-left"
			*ngIf="hasLeftSideNav"
			[ngClass]="{ 'sh-sidenav-button-toggle-opened': leftSideNav.opened }"
		>
			<div (click)="toggleLeft()" class="sh-slide-toggle-icon mat-elevation-z4">
				<mat-icon fontSet="prism-mat" fontIcon="keyboard_arrow_right" style="color: white;"></mat-icon>
			</div>
		</div>

		<mat-sidenav-container [hasBackdrop]="hasBackdrop">
			<div class="mat-elevation-z8 ab"></div>
			<!-- TODO: LBF 10/3/22 -->
			<!-- Left sidenav -->
			<!-- <mat-sidenav
				class="mat-elevation-z8"
				*ngIf="hasLeftSideNav"
				[opened]="leftSideNav.opened$ | async"
				[mode]="leftSideNav.mode$ | async"
			> -->
			<mat-sidenav
				class="mat-elevation-z8"
				*ngIf="hasLeftSideNav"
				[opened]="leftSideNav.opened$ | async"
				[mode]="'side'"
			>
				<mat-toolbar class="sh-layout-container-sidenav-left">
					<button mat-button mat-mdc-icon-button (click)="toggleLeft()">
						<mat-icon matListIcon fontSet="prism-mat" fontIcon="keyboard_arrow_left"></mat-icon>
					</button>
					<h3 class="mat-h3 sh-ellipsis">{{ leftSideNav.title }}</h3>
				</mat-toolbar>
				<ng-content select="sh-layout-content-left-sidenav"></ng-content>
			</mat-sidenav>

			<ng-content select="sh-layout-content"></ng-content>

			<!-- TODO: LBF 10/3/22 -->
			<!-- Right Sidenav -->
			<!-- <mat-sidenav
				class="mat-elevation-z8"
				*ngIf="hasRightSideNav"
				[opened]="rightSideNav.opened$ | async"
				[mode]="rightSideNav.mode$ | async"
				position="end"
			> -->
			<mat-sidenav
				class="mat-elevation-z8 right-sidenav"
				*ngIf="hasRightSideNav"
				[opened]="rightSideNav.opened$ | async"
				[mode]="isXSmallDevice() ? 'over' : 'side'"
				position="end"
			>
				<mat-toolbar class="sh-layout-container-sidenav-right">
					<button (click)="toggleRight()">
						<mat-icon matListIcon fontSet="prism-mat" fontIcon="keyboard_arrow_right"></mat-icon>
					</button>
					<span>{{ translate('cloud.device-management.property-panel.properties') }}</span>
					<h3 class="mat-h3 sh-ellipsis">{{ rightSideNav.title }}</h3>
				</mat-toolbar>
				<mat-divider></mat-divider>
				<ng-content select="sh-layout-content-right-sidenav"></ng-content>
			</mat-sidenav>
		</mat-sidenav-container>

		<!-- Right sidenav toggler -->
		<div
			class="sh-sidenav-button-toggle sh-sidetoggle-right"
			*ngIf="hasRightSideNav"
			[ngClass]="{ 'sh-sidenav-button-toggle-opened': rightSideNav.opened }"
		>
			<div (click)="toggleRight()" class="sh-slide-toggle-icon mat-elevation-z4">
				<mat-icon fontSet="prism-mat" fontIcon="keyboard_arrow_left"></mat-icon>
				<mat-divider></mat-divider>
				<span>{{ translate('cloud.device-management.property-panel.properties') }}</span>
			</div>
		</div>
	`,
	styleUrls: ['layout-container.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutContainerComponent implements AfterContentInit {
	@ContentChild(LayoutActionBarComponent, { static: false }) public actionBar!: LayoutActionBarComponent;
	@ContentChild(LayoutContentLeftSidenavComponent, { static: false })
	public leftSideNav!: LayoutContentLeftSidenavComponent;
	@ContentChild(LayoutContentRightSidenavComponent, { static: false })
	public rightSideNav!: LayoutContentRightSidenavComponent;
	@Input() public displayRightSideNav = true;

	@HostBinding('class.sh-layout-container-no-actionbar') public noActionBarCssClass = true;

	@Input() public hasBackdrop = false;

	public get hasActionbar(): boolean {
		return this.actionBar !== void 0;
	}

	public get hasLeftSideNav(): boolean {
		return this.leftSideNav !== void 0;
	}

	public get hasRightSideNav(): boolean {
		return this.displayRightSideNav && this.rightSideNav !== void 0;
	}

	public readonly isXSmallDevice = this.breakpointService.isLteXSmall;

	constructor(
		private cdr: ChangeDetectorRef,
		private translocoService: TranslocoService,
		private readonly breakpointService: BreakpointService
	) {}

	public translate(key: string): string {
		return this.translocoService.translate(key);
	}

	public ngAfterContentInit(): void {
		this.noActionBarCssClass = !this.hasActionbar;
	}

	public toggleRight(): void {
		this.rightSideNav.opened = !this.rightSideNav.opened;

		// Emit state change
		this.rightSideNav.openedChange.emit(this.rightSideNav.opened);
		this.cdr.markForCheck();
	}

	public toggleLeft(): void {
		this.leftSideNav.opened = !this.leftSideNav.opened;

		// Emit state change
		this.leftSideNav.openedChange.emit(this.leftSideNav.opened);
		this.cdr.markForCheck();
	}
}
