import {
	Component,
	Input,
	ViewChild,
	ElementRef,
	AfterViewInit,
	ChangeDetectionStrategy,
	Renderer2
} from '@angular/core';

export type CenterCardSize = 'small' | 'medium' | 'large' | 'full';

@Component({
	selector: 'sh-center-card-layout',
	templateUrl: './center-card-layout.component.html',
	styleUrls: ['./center-card-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CenterCardLayoutComponent implements AfterViewInit {
	@ViewChild('divWidth', { static: false }) public divWidthElm!: ElementRef;

	public sizeInternal: CenterCardSize = 'medium';

	private _maxWidth!: number;

	@Input()
	public set size(size: CenterCardSize) {
		if (!this._maxWidth) {
			this.sizeInternal = size;
		}
	}
	@Input(/*'max-width'*/)
	public set maxWidth(maxWidth: number) {
		this._maxWidth = maxWidth;
	}

	constructor(private renderer: Renderer2) {}

	public ngAfterViewInit(): void {
		if (this._maxWidth) {
			this.renderer.setStyle(this.divWidthElm.nativeElement, 'max-width', `${this._maxWidth}px`);
		}
	}
}
