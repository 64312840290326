/* libs/cloud/shared/ui/components/src/lib/legacy-layout/stacked-layout/sh-layout-actionbar.scss */
:host {
  display: flex;
  z-index: 6;
}
:host > .mat-toolbar {
  position: relative;
  border-top: 1px solid var(--mat-foreground-divider);
}
:host[sh-layout-actionbar-no-border] > .mat-toolbar {
  border-top: transparent;
}
/*# sourceMappingURL=sh-layout-actionbar.css.map */
