/* libs/cloud/shared/ui/components/src/lib/legacy-layout/stacked-layout/stacked-layout-appbar.scss */
:host {
  z-index: 2;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  background-color: var(--mat-background-card);
}
:host ::ng-deep .mat-toolbar.mat-elevation-z8 {
  z-index: 0;
}
:host ::ng-deep .sh-app-toolbar {
  z-index: 2;
}
/*# sourceMappingURL=stacked-layout-appbar.css.map */
