import { ValidatorFn } from '@angular/forms';

export interface FormDataDetails {
	label: string;
	name: string;
	validators: ValidatorFn[] | [];
	type: string;
	errorLabel?: string;
	data?: string[];
	disabled?: boolean;
	dependsOn?: string;
	formData?: SelectDropDownData[];
}
export interface IgniteDataObjectType {
	[key: string]: number | string | string[] | Date | undefined;
}
export interface IgniteDialogDto {
	name?: string;
	role?: string;
	userId?: string;
	expiryTime?: string;
	expirationTime?: string;
	groupId?: string;
	groupName?: string;
}

export interface SelectDropDownData {
	id: string;
	name: string;
}

export interface IgniteDialogData {
	action: string;
	formData?: FormDataDetails[];
	data?: IgniteDataObjectType;
	buttonListType?: string;
	description?: string;
	dialogFooter?: string;
}

export interface IgniteDialogDataResponse {
	data?: IgniteDialogDto;
}

export enum FormTypes {
	Select = 'select',
	Date = 'date',
	Text = 'text',
	Number = 'number',
	Email = 'email',
	Time = 'time',
	Textarea = 'textarea',
	Options = 'options',
	Checkbox = 'checkbox'
}
