import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AboutDialogComponent } from '../dialogs';
@Component({
	selector: 'sh-top-ribbon',
	templateUrl: './top-ribbon.component.html',
	styleUrls: ['./top-ribbon.component.scss']
})
export class TopRibbonComponent {
	@Input() public title!: string;
	@Input() public appVersion!: string;
	@Input() public showAccount = true;
	@Input() public showAbout = false;
	@Input() public showShureLogoWhenSignedIn = true;
	@Input() public isUserAuthenticated: boolean | null = false;
	@Output() public logoutClicked: EventEmitter<void> = new EventEmitter();

	constructor(
		private router: Router,
		private dialogRef: MatDialog
	) {}

	public goToAccount(): void {
		this.router.navigateByUrl('/account');
	}
	public openAboutDialog(): void {
		this.dialogRef.open(AboutDialogComponent, {
			width: '450px',
			data: { title: this.title, appVersion: this.appVersion }
		});
	}

	public async logout(): Promise<void> {
		this.logoutClicked.next();
	}

	public onLogoClick(): void {
		this.router.navigateByUrl('/');
	}
}
