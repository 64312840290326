/* libs/cloud/shared/ui/components/src/lib/legacy-layout/shared/layout-topbanner.scss */
:host {
  min-height: 24px;
  line-height: 24px;
  height: 24px;
  padding-left: 8px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  background-color: var(--mat-background-card);
}
:host > * {
  min-height: 24px;
  line-height: 24px;
  height: 24px;
}
/*# sourceMappingURL=layout-topbanner.css.map */
