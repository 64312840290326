import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * ```<sh-search-bar>``` is a simple input field for contextual searching.
 *
 * Search bar emits the user query on every change(live search), but it is also
 * possible to emit the query on enter key press or on search button click.
 *
 * The search bar accepts content projection on the right side of it and
 * is most commonly used with a drawer overlay component ```<sh-drawer-overlay>```
 * to display a search filter.
 *
 * An exemplary use of the search bar with drawer overlay:
 *
 * ```html
 * <sh-search-bar>
 * 	<sh-drawer-overlay>
 * 		<span>Filter content</span>
 * 	</sh-drawer-overlay>
 * </sh-search-bar>
 * ```
 */
@Component({
	selector: 'sh-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
	/**
	 * Initialize/set value of the query string.
	 */
	@Input() public queryString = '';

	/**
	 * Emits query string when button is clicked or enter key pressed.
	 */
	@Output() public querySubmitted = new EventEmitter<string>();

	/**
	 * Emits query string, for each character change
	 */
	@Output() public readonly queryChanged = new EventEmitter<string>();
}
