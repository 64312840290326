import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoaderModule } from '../../../loader';

import { CustomLoadingOverlayComponent } from './custom-loading-overlay.component';

@NgModule({
	declarations: [CustomLoadingOverlayComponent],
	imports: [CommonModule, LoaderModule],
	exports: [CustomLoadingOverlayComponent]
})
export class CustomLoadingOverlayModule {}
