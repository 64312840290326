import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';

class NgxResizeObserverService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.count = 0;
        this.elementMap = new Map();
        this.observer = null;
    }
    ngOnDestroy() {
        if (this.observer) {
            this.clearObserver();
        }
    }
    observe(element, callback, boxModel) {
        if (!this.observer) {
            this.observer = new ResizeObserver(resizes => {
                for (const resize of resizes) {
                    const cb = this.elementMap.get(resize.target);
                    if (cb) {
                        this.ngZone.run(() => {
                            cb(resize);
                        });
                    }
                }
            });
        }
        if (boxModel === 'border-box') {
            this.observer.observe(element, {
                box: 'border-box'
            });
        }
        else {
            this.observer.observe(element);
        }
        this.count += 1;
        this.elementMap.set(element, callback);
    }
    unobserve(element) {
        const cb = this.elementMap.get(element);
        if (cb && this.observer) {
            this.observer.unobserve(element);
            this.elementMap.delete(element);
            this.count -= 1;
            if (this.count === 0) {
                this.clearObserver();
            }
        }
    }
    clearObserver() {
        if (this.observer) {
            this.observer.disconnect();
        }
        this.observer = null;
        this.count = 0;
        this.elementMap = new Map();
    }
    /** @nocollapse */ static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverService, deps: [{ token: i0.NgZone }], target: i0.ɵɵFactoryTarget.Injectable }); }
    /** @nocollapse */ static { this.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverService }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverService, decorators: [{
            type: Injectable
        }], ctorParameters: () => [{ type: i0.NgZone }] });

class NgxResizeObserverDirective {
    constructor(elementRef, ngxResizeObserverService) {
        this.elementRef = elementRef;
        this.ngxResizeObserverService = ngxResizeObserverService;
        this.resizeBoxModel = '';
        this.onResize = new EventEmitter();
        this.observing = false;
    }
    ngAfterViewInit() {
        this.observe();
    }
    ngOnChanges(changes) {
        if (this.observing && (changes['resizeBoxModel'] || changes['onResize'])) {
            this.unobserve();
            this.observe();
        }
    }
    ngOnDestroy() {
        this.unobserve();
    }
    observe() {
        if (!this.observing) {
            this.ngxResizeObserverService.observe(this.elementRef.nativeElement, resize => this.onResize.emit(resize), this.resizeBoxModel);
            this.observing = true;
        }
    }
    unobserve() {
        if (this.observing) {
            this.ngxResizeObserverService.unobserve(this.elementRef.nativeElement);
            this.observing = false;
        }
    }
    /** @nocollapse */ static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverDirective, deps: [{ token: i0.ElementRef }, { token: NgxResizeObserverService }], target: i0.ɵɵFactoryTarget.Directive }); }
    /** @nocollapse */ static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "17.3.1", type: NgxResizeObserverDirective, selector: "[onResize]", inputs: { resizeBoxModel: "resizeBoxModel" }, outputs: { onResize: "onResize" }, usesOnChanges: true, ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[onResize]'
                }]
        }], ctorParameters: () => [{ type: i0.ElementRef }, { type: NgxResizeObserverService }], propDecorators: { resizeBoxModel: [{
                type: Input
            }], onResize: [{
                type: Output
            }] } });

class NgxResizeObserverModule {
    /** @nocollapse */ static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule }); }
    /** @nocollapse */ static { this.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverModule, declarations: [NgxResizeObserverDirective], exports: [NgxResizeObserverDirective] }); }
    /** @nocollapse */ static { this.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverModule, providers: [NgxResizeObserverService] }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.3.1", ngImport: i0, type: NgxResizeObserverModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [NgxResizeObserverDirective],
                    exports: [NgxResizeObserverDirective],
                    providers: [NgxResizeObserverService]
                }]
        }] });

/*
 * Public API Surface of ngx-resize-observer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxResizeObserverDirective, NgxResizeObserverModule, NgxResizeObserverService };

