<ng-content select="sh-sidenav-layout-topbanner"></ng-content>
<mat-sidenav-container (backdropClick)="close()" [ngClass]="{ 'sh-sidenav-layout-secnav-toggle': isSecDrawerOpen }">
	<mat-sidenav #sidenav [mode]="viewMode" [opened]="opened$ | async" class="mat-elevation-z8">
		<button mat-icon-button (click)="close()" *ngIf="isCloseButtonEnabled">
			<mat-icon matListIcon fontSet="prism-mat" fontIcon="keyboard_arrow_left"></mat-icon>
		</button>
		<ng-content select="sh-sidenav-layout-sidenav"></ng-content>
	</mat-sidenav>
	<ng-content select="sh-sidenav-layout-content"></ng-content>
</mat-sidenav-container>
<ng-content select="sh-sidenav-layout-footer"></ng-content>
