<div class="feature-container">
	<div class="feature-content">
		<ng-content select=".feature-content"></ng-content>
	</div>
	<div class="feature-footer">
		<sh-footer></sh-footer>
		<div class="feature-footer-supp">
			<ng-content select=".feature-footer-supp"></ng-content>
		</div>
	</div>
</div>
