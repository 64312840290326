import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'macAddress' })
export class MacAddressPipe implements PipeTransform {
	public transform(value: string): string {
		if (value) {
			return value.replace(/(..)/g, '$1:').slice(0, -1);
		}
		return '';
	}
}
