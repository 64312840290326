import { Pipe, PipeTransform } from '@angular/core';
import { asyncScheduler, Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Pipe({ name: 'throttleTime', pure: true })
export class ThrottleTimePipe<T> implements PipeTransform {
	public transform(stream: Observable<T>, time = 0, leading = true, trailing = true): Observable<T> {
		return stream.pipe(throttleTime(time, asyncScheduler, { leading, trailing }));
	}
}
