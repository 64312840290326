<div *transloco="let t">
	<div id="sh-no-rows-overlay-div">
		<img
			*ngIf="params?.customImageSrc"
			id="sh-no-rows-overlay-img"
			[src]="params?.customImageSrc | version | async"
		/>
		<span *ngIf="params?.title" id="sh-no-rows-overlay-title-span">{{ t(params?.title) }}</span>
		<span *ngIf="params?.message" id="sh-no-rows-overlay-message-span"> {{ t(params?.message) }} </span>
		<span id="sh-no-rows-overlay-secondary-message-span" *ngIf="params?.secondaryMessage">
			{{ t(params?.secondaryMessage) }}
		</span>
	</div>
</div>
