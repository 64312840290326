import { NgModule, ModuleWithProviders, Type } from '@angular/core';

import { ILoggingConfigProvider, LOGGING_CONFIG } from './config-providers/logging-config.provider';
import { StaticLoggingConfigProvider } from './config-providers/static-config.provider';
import { ILogger } from './ilogger';
import { Logger } from './logger';
import { LoggerService, LOGGER_SINKS } from './logger.service';
import { LoggingConfig } from './models';
import { LoggerSink } from './sinks/logger.sink';

export function loggerSinksFactory(...sinks: LoggerSink[]): LoggerSink[] {
	return sinks;
}

@NgModule({})
export class LoggingModule {
	public static forRoot(
		sinks: Type<LoggerSink>[],
		loggingConfig: LoggingConfig,
		loggingConfigProvider: Type<ILoggingConfigProvider> = StaticLoggingConfigProvider
	): ModuleWithProviders<LoggingModule> {
		return {
			ngModule: LoggingModule,
			providers: [
				sinks,
				{
					provide: LOGGING_CONFIG,
					useValue: loggingConfig
				},
				{
					provide: LOGGER_SINKS,
					useFactory: loggerSinksFactory,
					deps: sinks
				},
				{
					provide: ILogger,
					useClass: Logger,
					deps: [LoggerService]
				},
				{
					provide: ILoggingConfigProvider,
					useClass: loggingConfigProvider
				},
				{
					provide: LoggerService,
					useClass: LoggerService,
					deps: [LOGGER_SINKS, ILoggingConfigProvider]
				}
			]
		};
	}
}
