<form [formGroup]="formGroup" *transloco="let t">
	<h1 id="sh-feature-request-dialog-title-h1" mat-dialog-title>
		{{ t('cloud.shared.feature-request.feature-request') }}
	</h1>
	<div id="sh-feature-request-dialog-description-div">{{ t('cloud.shared.feature-request.feature-popup-info') }}</div>
	<mat-dialog-content id="sh-feature-request-dialog-content">
		<ng-container>
			<mat-form-field id="sh-feature-request-dialog-idea-summary-form-field">
				<mat-label id="sh-feature-request-dialog-idea-summary-mat-label">{{
					t('cloud.shared.feature-request.idea-summary')
				}}</mat-label>
				<input
					[placeholder]="t('cloud.shared.feature-request.idea-summary')"
					matInput
					[formControlName]="'title'"
				/>
				<mat-error *ngIf="formGroup.controls['title'].hasError('pattern')">
					{{ t('cloud.shared.error-labels.spaces-not-allowed') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-feature-request-dialog-description-form-field">
				<mat-label id="sh-feature-request-dialog-description-mat-label">{{
					t('cloud.shared.feature-request.more-details')
				}}</mat-label>
				<textarea
					rows="6"
					[placeholder]="t('cloud.shared.feature-request.more-details')"
					matInput
					[formControlName]="'description'"
				></textarea>
				<mat-error
					*ngIf="
						formGroup.controls['description'].touched &&
						formGroup.controls['description'].hasError('required')
					"
					>{{ t('cloud.shared.error-labels.required-field') }}
				</mat-error>
			</mat-form-field>
		</ng-container>
		<div id="sh-feature-request-dialog-contact-info-div">
			{{ t('cloud.shared.feature-request.feature-request-contact-info') }}
		</div>
	</mat-dialog-content>
	<div class="sh-feature-request-dialog-buttons" id="sh-feature-request-dialog-action-buttons-div">
		<button mat-button color="accent" (click)="closeDialog()" id="sh-feature-request-dialog-cancel-button">
			{{ t('cloud.shared.button-text.cancel') }}
		</button>
		<button
			[disabled]="formGroup.invalid || !isFormChanged"
			mat-button
			color="accent"
			id="sh-feature-request-dialog-confirm-button"
			(click)="doAction()"
		>
			{{ t('cloud.shared.button-text.send') }}
		</button>
	</div>
</form>
