/* libs/cloud/shared/ui/components/src/lib/legacy-layout/content-loader/content-loader.component.scss */
:host {
  height: 100%;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  flex: 1 1 0%;
  min-height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
:host > div {
  width: 288px;
  height: 192px;
}
/*# sourceMappingURL=content-loader.component.css.map */
