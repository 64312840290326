/* libs/cloud/shared/ui/components/src/lib/device-image/device-image.component.scss */
:host {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}
:host .sh-device-state-icon {
  float: right;
  margin-right: -2px;
  margin-top: 2px;
  width: 13px;
}
/*# sourceMappingURL=device-image.component.css.map */
