import { Component, HostBinding, input, Input, output } from '@angular/core';

/**
 * ```sh-is-button``` is a wrapper for a mat-icon-button adding a pulsating effect
 * when the button is active to visualise the identifying state for a device.
 */
@Component({
	selector: 'sh-id-button',
	templateUrl: './id-button.component.html',
	styleUrls: ['./id-button.component.scss']
})
export class IdButtonComponent {
	/**
	 * Id incrementer allowing for multiple <sh-id-button> in same view.
	 * @ignore internal
	 */
	public static nextId = 0;

	/**
	 * @ignore internal
	 */
	@Input()
	@HostBinding()
	public readonly id = `sh-id-button-${IdButtonComponent.nextId++}`;

	/**
	 * Determines if the ID button is identifying.
	 */
	public readonly isIdentifying = input(false);

	/**
	 * Disable ID button.
	 */
	public readonly disabled = input(true);

	/**
	 * Emits the value of isIdentifying on id button click.
	 */
	public readonly toggleIdentify = output<boolean>();

	public identifyDevice(event: Event): void {
		this.toggleIdentify.emit(!this.isIdentifying());
		event.stopPropagation();
	}
}
