/* libs/cloud/shared/ui/components/src/lib/legacy-layout/stacked-layout/layout-content.scss */
:host {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  overflow: overlay !important;
}
:host::-webkit-scrollbar {
  display: none;
}
:host:hover::-webkit-scrollbar {
  display: initial;
}
::ng-deep .sh-layout-container-sidenav-right {
  width: 240px;
}
::ng-deep .sh-layout-container-sidenav-right button {
  border: 0px;
  background: none;
  cursor: pointer;
}
/*# sourceMappingURL=layout-content.css.map */
