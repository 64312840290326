<div *transloco="let t">
	<button
		mat-button
		id="sh-export-button-menu"
		[matMenuTriggerFor]="dropDownMenu"
		(click)="$event.stopPropagation()"
		[disabled]="buttonDisabled"
	>
		{{ t('ag-grid.export') }}
		<mat-icon fontIcon="arrow_drop_down"></mat-icon>
	</button>
	<mat-menu #dropDownMenu="matMenu" class="sh-export-button-menu-panel">
		<ng-container *ngFor="let fileType of exportFileTypes">
			<button
				mat-menu-item
				class="sh-export-button-menu-item"
				id="{{ 'sh-export-' + fileType + '-button' }}"
				(click)="exportData(fileType)"
			>
				{{ t('ag-grid.' + fileType + 'Export') }}
			</button>
		</ng-container>
	</mat-menu>
</div>
