import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { WhiteSpaceValidator } from './whitespace.validator';

export enum InlineDeviceNameValidationRules {
	MaxLength = 31,
	Regex = '^[a-zA-Z0-9][a-zA-Z0-9-]*$'
}

export enum RoomNameValidationRules {
	MaxLength = 64,
	Regex = '^[a-zA-Z0-9 _()]*$'
}

export enum RoomPasswordValidationRules {
	MinLength = 8,
	MaxLength = 64
}

export enum RoomDescriptionValidationRules {
	MaxLength = 125
}

export function getDeviceNameValidators(): ValidatorFn[] {
	return [
		Validators.required,
		Validators.maxLength(InlineDeviceNameValidationRules.MaxLength),
		Validators.pattern(InlineDeviceNameValidationRules.Regex)
	];
}

export function getRoomNameValidators(): ValidatorFn[] {
	return [
		Validators.required,
		Validators.maxLength(RoomNameValidationRules.MaxLength),
		Validators.pattern(RoomNameValidationRules.Regex),
		WhiteSpaceValidator.valueHasConsecutiveSpaces,
		WhiteSpaceValidator.valueHasSpaceAtFirstOrLastCharacter
	];
}

export function getRoomPasswordValidators(): ValidatorFn[] {
	return [
		Validators.required,
		Validators.minLength(RoomPasswordValidationRules.MinLength),
		Validators.maxLength(RoomPasswordValidationRules.MaxLength)
	];
}

export function getRoomDescriptionValidators(): ValidatorFn[] {
	return [Validators.maxLength(RoomDescriptionValidationRules.MaxLength)];
}

export function getNumberValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		if (isNaN(value)) {
			return <ValidationErrors>{ numberinvalid: true };
		}

		return null;
	};
}

export function numberInRangeValidator(range: { min: number; max: number }): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (value === null || (value >= range.min && value <= range.max)) {
			return null;
		}

		return <ValidationErrors>{ outsideRange: true };
	};
}

/**
 * Validator function for checking the validity of a license group name.
 * Ensures that the name starts with a letter or number and contains only letters,
 * numbers, spaces, underscores, and hyphens.
 */
export function licenseGroupNameValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		// p{L} is used to allow the unicode support which will allow alphabets in all languages
		const startsWithLetterOrNumber = /^[\p{L}0-9]/u.test(control.value);
		// eslint-disable-next-line no-useless-escape
		const forbiddenCharactersRegEx = /^[0-9\p{L}_\s-]+[^:\/\\<>"|?*^,#&~{}\[\]()`';%!@$.+=]*$/u;
		const isValidFormat = forbiddenCharactersRegEx.test(control.value);
		const checkForWhiteSpaceRegEx = /^(?!\s)/.test(control.value);

		const errors: { [key: string]: boolean } = {};

		if (!checkForWhiteSpaceRegEx) {
			// eslint-disable-next-line dot-notation
			errors['whiteSpace'] = true;
		}

		if (!startsWithLetterOrNumber) {
			// eslint-disable-next-line dot-notation
			errors['invalidStart'] = true;
		}

		if (!isValidFormat) {
			// eslint-disable-next-line dot-notation
			errors['invalidCharacters'] = true;
		}

		// Return null if there are no errors, otherwise return the errors object
		return Object.keys(errors).length ? errors : null;
	};
}

/**
 * Validator function for checking the validity of a name.
 * Ensures that the name starts with a letter or number and contains only letters,
 * numbers, spaces, underscores, and hyphens.
 */
export function nameValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		const startsWithLetterOrNumber = /^\p{L}|\p{N}/u.test(control.value); // Starts with a letter or number
		const endsWithLetterOrNumber = /[\p{L}\p{N}]$/u.test(control.value); // Ends with a letter or number
		const validPattern = /^[\p{L}\p{N}\s_-]*$/u; // Allows letters, numbers, spaces, underscores and hyphens only
		const isValidFormat = validPattern.test(control.value);

		if (control.value === '') {
			return null;
		}
		if (!isValidFormat) {
			return { invalidCharacters: true };
		}
		if (!startsWithLetterOrNumber || !endsWithLetterOrNumber) {
			return { invalidStart: true };
		}

		return null;
	};
}

/**
 * Validator function for checking the validity of a user group name.
 * Ensures that the name starts/ends with a letter or number and contains only letters,
 * numbers, spaces, underscores, and hyphens.
 */
export function userGroupNameValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		const startsWithLetterOrNumber = /^\p{L}|\p{N}/u.test(control.value);
		const endsWithLetterOrNumber = /[\p{L}\p{N}]$/u.test(control.value);
		const validPattern = /^[\p{L}\p{N}\s_-]*$/u;
		const isValidFormat = validPattern.test(control.value);
		if (control.value.length <= 0) {
			return { emptyInput: true };
		}
		if (!startsWithLetterOrNumber) {
			return { invalidStart: true };
		}
		if (!endsWithLetterOrNumber) {
			return { invalidEnd: true };
		}
		if (control.value.length > 31) {
			return { invalidLength: true };
		}
		if (!isValidFormat) {
			return { invalidCharacters: true };
		}
		return null;
	};
}

/**
 * Validator function for checking the validity of domain names.
 * Ensures that the domain name contains only letters, hyphens, numbers
 * and 2 or 3 characters of top level domain.
 */
// TODO: TPD The below method needs to be uncommented once the validation pattern is confirmed.
// export function domainNameValidator() {
// 	return (control: AbstractControl): { [key: string]: unknown } | null => {
// 		// Regular expression to validate domain names
// 		const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/;

// 		// Split the input string by commas
// 		const domainNames = control.value.split(',');

// 		// Validate each domain name
// 		for (const domain of domainNames) {
// 			if (!domainRegex.test(domain)) {
// 				return { invalidCharacters: true }; // If any domain is invalid
// 			}
// 		}
// 		return null;
// 	};
// }

// exporting the regex so it can be used outside the context of a Validator
export const macAddressRegEx = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
export function getMacAddresValidators(): ValidatorFn[] {
	return [Validators.required, Validators.pattern(macAddressRegEx)];
}

// exporting the regex so it can be used outside the context of a Validator
export const serialNumberRegEx = /^[A-Za-z0-9_]+$/;
export function getSerialNumberValidators(): ValidatorFn[] {
	return [Validators.required, Validators.pattern(serialNumberRegEx)];
}
