/* libs/cloud/shared/ui/components/src/lib/top-ribbon/top-ribbon.component.scss */
.mat-toolbar {
  height: 50px;
  background-color: black !important;
}
.mat-toolbar .toolbar-app-name-label {
  font-size: 14px;
  margin-right: 12px;
  margin-top: 2px;
}
.toolbar-app-name-label {
  color: white;
}
button {
  width: 100%;
}
.role {
  margin-top: 2px;
  color: white;
  padding-right: 2rem;
  font-size: 14px;
}
.sh-top-arrow {
  padding: 0px;
}
.sh-top-arrow div {
  line-height: 36px;
}
.sh-top-arrow .valign-middle {
  vertical-align: middle;
  border: 2px solid var(--mat-shure-green) !important;
  border-radius: 50%;
}
::ng-deep .shure-top-ribbon-info .display-user,
::ng-deep .shure-top-ribbon-info mat-icon,
::ng-deep .shure-top-ribbon-info mat-button,
::ng-deep .shure-top-ribbon-info mat-icon-button {
  color: var(--mat-shure-green) !important;
}
::ng-deep .shure-top-ribbon-info .display-user {
  font-size: 14px;
  vertical-align: text-top;
}
.icon-style {
  font-size: 40px;
  margin-right: 40px;
  display: initial;
  vertical-align: middle;
  margin-top: 10px;
  color: white !important;
}
#sh-top-ribbon-logged-out-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0px;
}
#sh-top-ribbon-logged-in-toolbar {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
/*# sourceMappingURL=top-ribbon.component.css.map */
