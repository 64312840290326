/* eslint-disable dot-notation */
import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoService } from '@jsverse/transloco';

import { PrismStatusIndicatorComponent } from '@shure/prism-angular-components';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { DeviceImageProvider } from './device-image.provider';

@Component({
	selector: 'sh-device-image',
	templateUrl: './device-image.component.html',
	styleUrls: ['./device-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, MatTooltipModule, PrismStatusIndicatorComponent]
})
export class DeviceImageComponent implements OnChanges {
	/**
	 * Determines if the device is in virtual/live mode
	 */
	@Input()
	public isVirtual = false;

	/**
	 * Determines if the device is offline
	 */
	@Input()
	public isOffline = false;

	/**
	 * Determines if the device has incompatible firmware
	 */
	@Input()
	public incompatibleFirmware = false;

	/**
	 * Determines the device model for the image to be displayed
	 */
	@Input() public deviceModel!: DeviceModel | undefined;

	/**
	 * Determines if the device is password protected
	 */
	@Input() public isPasswordEnabled!: boolean | undefined;

	/**
	 * Determines if the locked device was successfully authenticated
	 * It is set to false, until we get full details on the authentication process.
	 */
	@Input() public isAuthenticated = false;

	/**
	 * Determines the number of channels
	 * Used for devices that show the channel count in the image
	 */
	@Input() public channelCount: number | undefined;

	/**
	 * Disable the tooltip of the image (default false)
	 */
	@Input() public disableTooltip = false;

	/**
	 * Displays linking status for supporting devices
	 */
	@Input() public linked: boolean | undefined = undefined;

	/**
	 * Path for the device image to be rendered
	 *
	 * @ignore internal
	 */
	public backgroundImage!: string;

	/**
	 * Device tooltip
	 *
	 * @ignore internal
	 */
	public deviceTooltip!: string;

	constructor(
		private readonly provider: DeviceImageProvider,
		private readonly translocoService: TranslocoService
	) {}

	/**
	 * On one of the input properties change, calls the deviceImageService to get the path for the device image to be rendered
	 */
	public ngOnChanges(_changes: SimpleChanges): void {
		if (this.deviceModel) {
			const imageInfo = this.provider.getImage(this.deviceModel, this.channelCount);
			const imageWithPath = this.isVirtual ? imageInfo.virtual : imageInfo.live;
			this.backgroundImage = `url(${imageWithPath})`;
		}
		if (_changes['isVirtual'] || _changes['isPasswordEnabled'] || _changes['isAuthenticated']) {
			this.deviceTooltip = this.getDeviceTooltip();
		}
	}

	private getDeviceTooltip(): string {
		if (!this.isVirtual && this.isPasswordEnabled) {
			return `${this.deviceModel}: ${
				this.isAuthenticated
					? this.translocoService.translate('common.device-tooltips.unlocked')
					: this.translocoService.translate('common.device-tooltips.locked')
			}`;
		}
		return this.deviceModel ?? '';
	}
}
