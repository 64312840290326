/* libs/cloud/shared/ui/components/src/lib/cell-renderers/device-name-renderer/device-name-renderer.component.scss */
:host {
  width: 100%;
  height: 100%;
}
:host ::ng-deep .mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 32px;
  padding: 0;
}
:host .sh-device-name-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
:host .sh-device-state {
  margin-right: 10px;
  width: 5px;
  align-self: stretch;
}
:host .sh-device-image {
  min-width: 30px;
  margin: 0 10px 0 2px;
}
:host .sh-device-name-container {
  margin-right: 8px;
  align-items: center;
  display: flex;
}
:host .device-name-text {
  word-break: break-all;
  text-wrap: pretty;
  line-height: 15px;
  max-height: 32px;
  overflow: hidden;
}
:host sh-id-button {
  align-self: center;
}
/*# sourceMappingURL=device-name-renderer.component.css.map */
