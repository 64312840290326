/* libs/cloud/shared/ui/components/src/lib/legacy-layout/shared/layout-footer.scss */
:host {
  z-index: 1000 !important;
  flex: 1 1 48px;
  box-sizing: border-box;
  max-height: 48px;
  min-height: 48px;
  flex-direction: row;
  display: flex;
  background-color: var(--mat-background-card);
}
:host > .mat-toolbar > span {
  flex: 1 1 0%;
  box-sizing: border-box;
}
:host > .mat-toolbar > span + img {
  width: 89px;
  height: 16px;
  opacity: 0.12;
  margin-top: 4px;
  filter: var(--sh-shared-logo-image-filter);
}
/*# sourceMappingURL=layout-footer.css.map */
