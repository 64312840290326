/* libs/cloud/shared/ui/components/src/lib/ag-grid-components/overlays/no-rows-overlay/no-rows-overlay.component.scss */
:host #sh-no-rows-overlay-div {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 40px;
  font-size: 17px !important;
  width: 390px;
  text-align: center;
}
:host #sh-no-rows-overlay-img {
  height: 135px;
  max-width: 300px;
  margin: 0 auto;
}
:host #sh-no-rows-overlay-title-span {
  font-size: 21px !important;
}
:host #sh-no-rows-overlay-message-span {
  margin-top: 10px;
}
@media (max-width: 480px) {
  :host #sh-no-rows-overlay-div {
    margin-top: 20px;
    font-size: 15px !important;
  }
  :host #sh-no-rows-overlay-title-span {
    font-size: 17px !important;
  }
  :host #sh-no-rows-overlay-message-span {
    margin-top: 0px;
  }
  :host #sh-no-rows-overlay-secondary-message-span {
    margin-top: 0px;
  }
  :host #sh-no-rows-overlay-img {
    height: 40px;
  }
}
@media (min-width: 481px) and (max-width: 932px) {
  :host #sh-no-rows-overlay-img {
    height: 40px;
  }
}
@media (min-width: 481px) and (max-width: 932px) and (orientation: landscape) {
  :host #sh-no-rows-overlay-div {
    margin-top: 20px;
    font-size: 11px !important;
  }
  :host #sh-no-rows-overlay-title-span {
    font-size: 13px !important;
  }
  :host #sh-no-rows-overlay-message-span {
    margin-top: 0px;
  }
  :host #sh-no-rows-overlay-secondary-message-span {
    margin-top: 0px;
  }
  :host #sh-no-rows-overlay-img {
    height: 40px;
  }
}
@media only screen and (max-width: 400px) {
  :host #sh-no-rows-overlay-title-span {
    padding: 0 70px;
  }
  :host #sh-no-rows-overlay-message-span {
    padding: 0 60px;
  }
}
@media only screen and (max-height: 600px) {
  :host #sh-no-rows-overlay-img {
    height: 40px;
  }
}
/*# sourceMappingURL=no-rows-overlay.component.css.map */
