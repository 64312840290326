export enum TokenError {
	MissingRole = 'MissingRole',
	MissingTenant = 'MissingTenant',
	Unknown = 'Unknown'
}

export interface UserProfile {
	firstName: string;
	lastName: string;
	email: string;
	locale?: string;
	role: string;
	userId: string;
	orgId: string;
	orgName: string;
}
/* eslint-disable @typescript-eslint/naming-convention */
export interface DecodedToken {
	shure_role: string;
	shure_tenant: string;
	shure_userId: string;
	shure_tenant_name: string;
}

export interface TokenResponse {
	token_type: string;
	expires_in: number;
	access_token: string;
	scope: string;
	refresh_token: string;
	id_token: string;
}
