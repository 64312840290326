<form [formGroup]="formGroup" *transloco="let t">
	<h1 id="sh-dialog-box-title-h1" mat-dialog-title>{{ title }}</h1>
	<div *ngIf="dialogData?.description" id="sh-dialog-box-description-div">{{ dialogData?.description }}</div>
	<mat-dialog-content id="sh-dialog-box-content">
		<ng-container *ngFor="let formRow of formData">
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Text">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<input [placeholder]="formRow.label" matInput [formControlName]="formRow.name" />
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
					>{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('pattern') && formGroup.controls[formRow.name].touched
					"
					>{{ t('cloud.shared.error-labels.spaces-not-allowed') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('maxlength') &&
						formGroup.controls[formRow.name].touched
					"
					>{{
						t('cloud.shared.error-labels.must-be-max-length', {
							number: formGroup.controls[formRow.name].errors?.['maxlength']?.requiredLength
						})
					}}
				</mat-error>
				<mat-error *ngIf="formGroup.controls[formRow.name].errors?.['emptyInput']">
					{{ t('cloud.shared.error-labels.name-enter-value', { label: formRow.errorLabel }) }}
				</mat-error>
				<mat-error *ngIf="formGroup.controls[formRow.name].errors?.['invalidStart']">
					{{ t('cloud.shared.error-labels.name-start-with-space', { label: formRow.errorLabel }) }}
				</mat-error>
				<mat-error *ngIf="formGroup.controls[formRow.name].errors?.['invalidEnd']">
					{{ t('cloud.shared.error-labels.name-ends-with-space', { label: formRow.errorLabel }) }}
				</mat-error>
				<mat-error *ngIf="formGroup.controls[formRow.name].errors?.['invalidCharacters']">
					{{ t('cloud.shared.error-labels.name-contains-invalid-character', { label: formRow.errorLabel }) }}
				</mat-error>
				<mat-error *ngIf="formGroup.controls[formRow.name].errors?.['invalidLength']">
					{{ t('cloud.shared.error-labels.name-length-too-long', { label: formRow.errorLabel }) }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Email">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<input [placeholder]="formRow.label" matInput [formControlName]="formRow.name" />
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
					>{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('pattern') && formGroup.controls[formRow.name].touched
					"
					>{{ t('cloud.shared.error-labels.enter-a-valid-email-id') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Number">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<input
					[placeholder]="formRow.label"
					type="number"
					matInput
					[formControlName]="formRow.name"
					id="sh-dialog-box-{{ formRow.name }}-input"
					floatLabel="never"
				/>

				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
				>
					{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('pattern') && formGroup.controls[formRow.name].touched
					"
					>{{ t('cloud.shared.error-labels.only-numerics-are-allowed') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Select">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<mat-select
					id="sh-dialog-box-{{ formRow.name }}-mat-select"
					matNativeControl
					[placeholder]="formRow.label"
					[formControlName]="formRow.name"
				>
					<mat-option *ngFor="let dataValue of formRow.data" [value]="dataValue">
						{{ t('cloud.shared.labels.' + dataValue.toLowerCase()) }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="!formGroup.controls[formRow.name].valid && formGroup.controls[formRow.name].touched">
					{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
			</mat-form-field>
			<!-- The formType "Options" is used when we have different value and display label text in our form data. -->
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Options">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<mat-select
					id="sh-dialog-box-{{ formRow.name }}-mat-select"
					matNativeControl
					[placeholder]="formRow.label"
					[formControlName]="formRow.name"
				>
					<mat-option *ngFor="let dataValue of formRow.formData" [value]="dataValue.id">
						{{ dataValue.name }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="!formGroup.controls[formRow.name].valid && formGroup.controls[formRow.name].touched">
					{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Date">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<input
					matInput
					[matDatepicker]="picker"
					[formControlName]="formRow.name"
					[placeholder]="'MM / DD / YYYY'"
					[min]="minDate"
					id="sh-dialog-box-{{ formRow.name }}-input"
				/>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
				<mat-error *ngIf="formGroup.controls[formRow.name].hasError"
					>{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field id="sh-dialog-field-{{ formRow.name }}" *ngIf="formRow.type === formType.Textarea">
				<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
				<textarea [placeholder]="formRow.label" matInput [formControlName]="formRow.name"></textarea>
				<mat-error
					*ngIf="
						formGroup.controls[formRow.name].hasError('required') &&
						formGroup.controls[formRow.name].touched
					"
				>
					{{ t('cloud.shared.error-labels.invalid-value') }}
				</mat-error>
			</mat-form-field>
			<ng-container
				*ngIf="
					(formRow.dependsOn && formGroup.controls[formRow.dependsOn].value ? true : false) ||
					!formRow.dependsOn
				"
			>
				<mat-form-field *ngIf="formRow.type === formType.Time">
					<mat-label id="sh-dialog-box-{{ formRow.name }}-mat-label">{{ formRow.label }}</mat-label>
					<input matInput type="time" min="00:00" max="23:59" [formControlName]="formRow.name" />
				</mat-form-field>
			</ng-container>
		</ng-container>
		<div *ngIf="dialogData?.dialogFooter" id="sh-dialog-box-feature-request-contact-info-div">
			{{ dialogData?.dialogFooter }}
		</div>

		<div
			[ngClass]="dialogData?.buttonListType === 'fullWidth' ? 'sh-button-list' : 'sh-mat-dialog-actions'"
			id="sh-dialog-box-action-buttons-div"
		>
			<button mat-button color="accent" (click)="closeDialog()" id="sh-dialog-button-cancel">
				{{ t('cloud.shared.button-text.cancel') }}
			</button>
			<button
				[disabled]="formGroup.invalid || !isFormChanged"
				mat-button
				color="accent"
				(click)="doAction()"
				id="sh-dialog-box-confirm-button"
			>
				{{ action | transloco }}
			</button>
		</div>
	</mat-dialog-content>
</form>
