import { Component, Inject, Input } from '@angular/core';

import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

@Component({
	selector: 'sh-my-account-side-nav',
	templateUrl: './my-account-side-nav.component.html',
	styleUrls: ['./my-account-side-nav.component.scss']
})
export class MyAccountSideNavComponent {
	@Input() public navigateTo!: string;
	public showSideNavItem = false;
	constructor(@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment) {}
}
