import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'sh-empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateComponent {
	@Input() public title!: string;
	@Input() public src!: string;
	@Input() public actionTitle!: string;

	@Output() public actionClick = new EventEmitter();

	public onClick($event: unknown): void {
		this.actionClick.emit($event);
	}
}
