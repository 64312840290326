export enum DeviceModel {
	AD600 = 'AD600',
	ADTD = 'ADTD',
	ADTQ = 'ADTQ',
	ANIUSB = 'ANIUSB-MATRIX',
	IMXROOM = 'IMX-Room',
	IMXCOMPUTE = 'IMX-Compute',
	MCR = 'ANX4',
	MXA310 = 'MXA310',
	MXA7102FT = 'MXA710-2FT',
	MXA7104FT = 'MXA710-4FT',
	MXA901R = 'MXA901-R',
	MXA901S = 'MXA901-S',
	MXA902S = 'MXA902-S',
	MXA910 = 'MXA910',
	MXA920S = 'MXA920-S',
	MXA920R = 'MXA920-R',
	MXN5C = 'MXN5-C',
	MXN6P = 'MXN6-P',
	MXNAMP = 'MXN-AMP',
	MXAMUTE = 'MXA-MUTE',
	MXWAPXD2 = 'MXWAPXD2',
	MXWAPX = 'MXWAPX',
	MXWNDX4 = 'MXWNDX4',
	MXWNDX4G = 'MXWNDX4G',
	MXWNDX8 = 'MXWNDX8',
	MXWNDX8G = 'MXWNDX8G',
	MXW1X = 'MXW1X',
	MXW2X = 'MXW2X',
	MXW6X = 'MXW6X',
	MXW8X = 'MXW8X',
	P300 = 'P300',
	UCWALL = 'UC-WALL',
	UNKNOWN = 'UNKNOWN'
}
