import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OrganizationsStoreService } from './organizations-store.service';

@NgModule({
	imports: [CommonModule],
	providers: [OrganizationsStoreService]
})
export class OrganizationsStoreModule {}
