import { DeviceCondition, DeviceConditionSeverity } from './device-condition';

export class SortedDeviceConditionCollection {
	private _conditions: DeviceCondition[] = [];

	constructor(conditions?: DeviceCondition[]) {
		if (conditions) {
			this.add(...conditions);
		}
	}

	public static readonly sortFn = (a: DeviceCondition, b: DeviceCondition): number => {
		if (a.severity === DeviceConditionSeverity.Moderate && b.severity === DeviceConditionSeverity.High) {
			return 1;
		}

		if (a.severity === DeviceConditionSeverity.High && b.severity === DeviceConditionSeverity.Moderate) {
			return -1;
		}

		return 0;
	};

	public get conditions(): DeviceCondition[] {
		return this._conditions;
	}

	public get containsHighSeverityCondition(): boolean {
		return this._conditions.length > 0 && this._conditions[0].severity === DeviceConditionSeverity.High;
	}

	public add(...conditions: DeviceCondition[]): void {
		this._conditions = this._conditions.concat(conditions).sort(SortedDeviceConditionCollection.sortFn);
	}

	public toString(): string {
		return this._conditions.map((c) => c.description).join('\n');
	}
}
