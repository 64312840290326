/* libs/cloud/shared/ui/components/src/lib/ag-grid-components/export-button/export-button.component.scss */
:host #sh-export-button-menu {
  background-color: var(--prism-color-theme-surface) !important;
  color: var(--prism-color-theme-accent-primary) !important;
  display: inline-flex;
  align-items: center;
  border: none;
  outline: none;
  line-height: inherit;
  letter-spacing: 1px;
  padding-left: 12px;
  vertical-align: middle;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 16px !important;
  height: 32px !important;
  min-width: 90px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
:host #sh-export-button-menu:disabled {
  color: var(--prism-color-theme-disabled-contrast) !important;
  cursor: default;
}
::ng-deep .sh-export-button-menu-panel {
  width: auto !important;
  min-width: 139px !important;
}
::ng-deep .sh-export-button-menu-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px !important;
  font-weight: 500;
  padding: 8px 12px;
}
/*# sourceMappingURL=export-button.component.css.map */
