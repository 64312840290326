import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Injectable, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BreakpointService {
	public readonly isLteXSmall = this.createSignal([Breakpoints.XSmall]);
	public readonly isLteSmall = this.createSignal([Breakpoints.XSmall, Breakpoints.Small]);
	public readonly isGteSmall = this.createSignal([
		Breakpoints.Small,
		Breakpoints.Medium,
		Breakpoints.Large,
		Breakpoints.XLarge
	]);
	public readonly isHandsetLandscape = this.createSignal([Breakpoints.HandsetLandscape]);
	public readonly isLteMedium = this.createSignal([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]);
	public readonly isGteMedium = this.createSignal([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]);
	// Since we assume most users are on desktop, default to "true" for the large breakpoint only
	public readonly isGteLarge = this.createSignal([Breakpoints.Large, Breakpoints.XLarge], true);
	public readonly isLandscape = this.createSignal(['(orientation: landscape)']);
	public readonly hasPointer = signal(true);

	constructor(
		private readonly breakpointObserver: BreakpointObserver,
		private mediaMatcher: MediaMatcher
	) {
		this.initHasPointerSignal();
	}

	private createSignal(breakpoints: string[], initialValue = false): Signal<boolean> {
		return toSignal(
			this.breakpointObserver.observe(breakpoints).pipe(
				debounceTime(100),
				map((value) => value.matches)
			),
			{ initialValue }
		);
	}

	//
	//  Initializes and manages the value of the hasPointer() signal based on the
	//  the mediaMatcher. The signal emits true or false based if the user has a
	//  pointer device. The intent of this was to know if the user has a device
	//  that can be used for pointing or hovering.
	//
	private initHasPointerSignal(): void {
		const mediaQueryList = this.mediaMatcher.matchMedia('(pointer:fine)');
		this.hasPointer.update(() => mediaQueryList.matches);

		// listen & react to changes
		mediaQueryList.addEventListener('change', (event: MediaQueryListEvent) => {
			this.hasPointer.update(() => event.matches);
		});
	}
}
