/* libs/cloud/shared/ui/components/src/lib/dialogs/dialog-box-ignite/dialog-box.component.scss */
:host mat-form-field {
  width: 100%;
  margin-bottom: 12px;
}
:host mat-mdc-dialog-content {
  margin: 0px -24px 6px -24px;
}
:host .sh-mat-dialog-actions {
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  box-sizing: content-box;
  float: right;
}
:host .sh-mat-dialog-actions .mat-mdc-button {
  min-width: 88px;
  font-size: 13px;
}
:host .sh-button-list {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 24px 0;
}
:host .sh-button-list .mat-mdc-button {
  border-radius: 1rem !important;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
}
:host .sh-button-list .mat-mdc-button:hover {
  background-color: rgba(255, 255, 255, 0.0862745098);
  color: #ffffff;
}
:host .sh-button-list .mat-mdc-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
:host mat-label {
  color: #ffffff !important;
}
:host #sh-dialog-box-permitted-domains-info-div,
:host #sh-dialog-box-description-div {
  padding: 0 24px;
}
:host #sh-dialog-box-feature-request-contact-info-div {
  font-size: 14px;
}
:host .mat-form-field-disabled mat-label {
  color: #6e6e6e !important;
}
:host ::ng-deep .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
}
:host ::ng-deep .mat-mdc-dialog-surface.mdc-dialog__surface {
  background-color: #333335 !important;
}
:host ::ng-deep .mat-mdc-input-element {
  color: #ffffff !important;
}
/*# sourceMappingURL=dialog-box.component.css.map */
