import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'sh-visibility-toggle-button',
	templateUrl: './visibility-toggle-button.component.html',
	styleUrls: ['./visibility-toggle-button.component.scss']
})
export class VisibilityToggleButtonComponent {
	@Input() public visible!: boolean;

	/**
	 * Event emitted when the button is clicked
	 */
	@Output() public toggle = new EventEmitter<boolean>();

	/**
	 * Toggle hidden property
	 */
	public toggleVisibility(event: Event): void {
		event.stopPropagation();
		this.toggle.emit(!this.visible);
	}
}
