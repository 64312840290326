/* libs/cloud/shared/ui/components/src/lib/dialogs/dialog-box/dialog-box.component.scss */
mat-form-field {
  width: 100%;
  margin-bottom: 12px;
}
mat-mdc-dialog-content {
  margin-top: 0px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 6px;
}
.mat-dialog-actions {
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  box-sizing: content-box;
  float: right;
}
.mat-dialog-actions .mat-mdc-button {
  min-width: 88px;
  font-size: 13px;
}
.button-list {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px;
}
.button-list .mat-mdc-button {
  background-color: #b2ff33;
  border-radius: 0% !important;
  width: 9rem;
  height: 3rem !important;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
}
.button-list .mat-mdc-button:hover {
  background-color: #000000;
  color: #fff;
}
.button-list .mat-mdc-button:not(:hover):not([disabled]) {
  color: #000;
}
.button-list .mat-mdc-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
/*# sourceMappingURL=dialog-box.component.css.map */
