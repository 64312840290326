/* libs/cloud/shared/ui/components/src/lib/feature-container/feature-container.component.scss */
.feature-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  justify-content: space-between;
  overflow: hidden;
}
.feature-content {
  flex-grow: 1;
  overflow: hidden;
}
/*# sourceMappingURL=feature-container.component.css.map */
