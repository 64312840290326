/* libs/cloud/shared/utils/inactivity/src/lib/ui/inactivity-dialog.component.scss */
:host #sh-incactivity-warning-dialog-container-div {
  min-width: 350px;
  overflow-x: auto;
}
:host #sh-inactivity-dialog-button-group-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}
:host .sh-inacitivity-dialog-inactivity-action {
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #b2ff33;
}
:host .sh-inacitivity-dialog-inactivity-action:hover {
  border-radius: 1rem !important;
}
:host [mat-dialog-actions] {
  margin-top: 1em;
}
@media (max-width: 480px) {
  :host .sh-inacitivity-dialog-inactivity-action {
    padding: 0px 5px 0px 5px;
  }
}
/*# sourceMappingURL=inactivity-dialog.component.css.map */
