import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	Renderer2
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { map, Observable, Subject, tap } from 'rxjs';

import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import {
	OrganizationsStoreService,
	VerificationStatus
} from '@shure/cloud/shared/services/organizations-store-service';
import { PipesModule } from '@shure/cloud/shared/ui/pipes';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

import { AppAccessNotConfiguredComponent } from '../app-access-not-configured';
import { BreadcrumbsModule, IBreadcrumb, IBreadcrumbClickEvent } from '../breadcrumbs';
import { FooterModule } from '../footer';
import { HeaderToolbarModule, HeaderToolbarService } from '../header-toolbar';
import {
	SecnavLayoutStateFacade,
	SecondaryNavLayoutModule,
	SidenavLayoutStateFacade,
	StackedLayoutModule
} from '../legacy-layout';

import { SecondaryNavList, SecondaryNavService, ToggleSecondayNavList } from './secondary-nav.service';

@Component({
	selector: 'sh-secondary-nav',
	styleUrls: ['./secondary-nav.component.scss'],
	templateUrl: 'secondary-nav.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatListModule,
		MatDividerModule,
		MatIconModule,
		RouterModule,
		TranslocoModule,
		FlexLayoutModule,
		StackedLayoutModule,
		PipesModule,
		HeaderToolbarModule,
		FooterModule,
		SecondaryNavLayoutModule,
		BreadcrumbsModule,
		AppAccessNotConfiguredComponent
	]
})
export class SecondaryNavComponent implements OnInit, OnDestroy, AfterViewInit {
	public isOpen$: Observable<boolean> | undefined;
	private destroy$: Subject<void> = new Subject<void>();
	public readonly isSmallDevice = this.breakPointService.isLteXSmall;
	public readonly isLandscape = this.breakPointService.isHandsetLandscape;
	public getSecondaryNavListItems$!: Observable<SecondaryNavList[]>;
	private secondaryNavList!: SecondaryNavList[];
	public breadCrumb!: IBreadcrumb[];
	public readonly breadCrumbs = toSignal(this.headerToolbarService.getBreadCrumb$(), {
		initialValue: []
	});
	public activeListItem$!: Observable<string>;
	public listItemUnavailableContent: {
		imageSrc: string;
		header: string;
		text: string;
		requestButton?: boolean;
	};
	public routeTitle = '';
	public secondaryNavHeader = '';
	constructor(
		private layoutStateFacade: SecnavLayoutStateFacade,
		private sideNavStateFacade: SidenavLayoutStateFacade,
		private router: Router,
		private route: ActivatedRoute,
		public secondaryNavService: SecondaryNavService,
		private breakPointService: BreakpointService,
		private headerToolbarService: HeaderToolbarService,
		private translocoService: TranslocoService,
		private elementRef: ElementRef,
		private readonly renderer: Renderer2,
		private organizationsStore: OrganizationsStoreService,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment
	) {
		this.listItemUnavailableContent = {
			imageSrc: '',
			header: '',
			text: ''
		};
	}

	public ngOnInit(): void {
		// eslint-disable-next-line dot-notation
		this.routeTitle = this.route.snapshot.data['title'];
		this.getSecondaryNavListItems$ = this.secondaryNavService.getSecondaryNavListItems$().pipe(
			// Sorting the nav list items to be in asc order.
			map((secondaryNavList) => secondaryNavList.sort((a, b) => a.listItem.localeCompare(b.listItem))),
			tap((secondaryNavList) => {
				if (secondaryNavList.length > 0) {
					this.secondaryNavList = secondaryNavList;
					this.activeListItem$ = this.secondaryNavService.getActiveListItem$();

					this.headerToolbarService.setHeader(secondaryNavList[0].title ?? '');

					this.isOpen$ = this.layoutStateFacade.isOpen$;
					if (!history.state.row && !secondaryNavList.some((c) => this.router.url.includes(c.key))) {
						this.secondaryNavService.setActiveListItem(secondaryNavList[0].key);
						const secondaryNavPath =
							secondaryNavList[0].keyInParams === false
								? `${secondaryNavList[0].feature}/${secondaryNavList[0].path}`
								: `${secondaryNavList[0].feature}/${secondaryNavList[0].key}/${secondaryNavList[0].path}`;
						this.router.navigate([secondaryNavPath]);
					} else {
						secondaryNavList.map((navList) => {
							if (this.router.url.includes(navList.key)) {
								this.secondaryNavService.setActiveListItem(navList.key);
								this.headerToolbarService.setHeader(navList.title ?? '');
							}
						});
					}
				} else {
					if (this.router.url.includes('license')) {
						this.secondaryNavService.setToggleSecondaryNav(ToggleSecondayNavList.Licenses);
						this.listItemUnavailableContent.imageSrc = 'assets/images/no-license-available.svg';
						const accountInfo = this.organizationsStore.accountInformation();
						this.listItemUnavailableContent.header = this.translocoService.translate(
							'cloud.shure-cloud.license-management.no-licenses-to-display'
						);
						const verificationStatus = accountInfo.organizationDetails?.verificationStatus;
						if (
							this.appEnv.featureFlags?.verifiedOrganizationLicenses !== false &&
							(verificationStatus === VerificationStatus.NONVERIFIED ||
								verificationStatus === VerificationStatus.REQUESTED)
						) {
							this.listItemUnavailableContent.text = this.translocoService.translate(
								'cloud.shure-cloud.license-management.convert-to-business-account'
							);
							this.listItemUnavailableContent.requestButton = true;
						} else {
							this.listItemUnavailableContent.text = this.translocoService.translate(
								'cloud.shure-cloud.license-management.licenses-show-up-after-purchase'
							);
						}
					}
				}
			})
		);
	}

	public ngAfterViewInit(): void {
		if (this.secondaryNavList) {
			if (this.secondaryNavList.some((c) => this.router.url.includes(c.key))) {
				this.secondaryNavList.map((navList) => {
					if (this.router.url.includes(navList.key)) {
						this.toggleActiveProductType(navList);
					}
				});
			} else {
				this.toggleActiveProductType(this.secondaryNavList[0]);
			}
		}
	}

	public navigateToPath(event: SecondaryNavList): void {
		this.secondaryNavService.setActiveListItem(event.key);
		const updatedNavList = this.secondaryNavList.filter((navList) => event.listItem !== navList.listItem);
		this.toggleActiveProductType(event, updatedNavList);
		const secondaryNavPath =
			event.keyInParams === false
				? `${event.feature}/${event.path}`
				: `${event.feature}/${event.key}/${event.path}`;
		this.router.navigate([secondaryNavPath]);
		this.headerToolbarService.setHeader(event.title ?? '');
	}

	/**
	 * Handle Breadcrumb click event and redirect to page
	 * @param breadCrumb - Breadcrumb which is clicked
	 */
	public breadCrumbRedirect(breadCrumb: IBreadcrumbClickEvent): void {
		this.router.navigate([`/${breadCrumb.id}`]);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.headerToolbarService.setHeader('');
	}
	public openSidenav(): void {
		this.sideNavStateFacade.open();
	}

	/**
	 * This method will toggle background color for active list item based on unique id.
	 * @param event - navigation list selected
	 * @param updatedNavList - updated list which excludes inactive list items
	 */
	private toggleActiveProductType(event: SecondaryNavList, updatedNavList?: SecondaryNavList[]): void {
		const navListElementRef = this.elementRef.nativeElement.querySelector(`#sh-secondary-nav-${event.id}`);
		this.renderer.setStyle(navListElementRef, 'background-color', 'var(--prism-color-theme-surface-variant)');
		updatedNavList?.forEach((navList) => {
			const navListElementRef = this.elementRef.nativeElement.querySelector(`#sh-secondary-nav-${navList.id}`);
			this.renderer.removeStyle(navListElementRef, 'background-color');
		});
	}
}
