export enum DeviceErrorType {
	None = 'NONE',
	Offline = 'OFFLINE',
	DiscoveryError = 'DISCOVERY_ERROR',
	IncompatibleFirmware = 'INCOMPATIBLE_FIRMWARE',
	LicenseError = 'LICENSE_ERROR',
	PoEGeneric = 'POE_GENERIC',
	PoELoudspeaker = 'POE_LOUDSPEAKER',
	InvalidFirmware = 'INVALID_FIRMWARE',
	DanteLocked = 'DANTE_LOCKED',
	LicenseWarning = 'LICENSE_WARNING',
	LoudspeakerOff = 'LOUDSPEAKER_OFF',
	NewDevice = 'NEW_DEVICE',
	EncryptionError = 'ENCRYPTION_ERROR',
	WeakRFStrengthError = 'WEAK_RF_STRENGTH_ERROR',
	OutOfRangeError = 'OUT_OF_RANGE_ERROR',
	ChargingError = 'CHARGING_ERROR',
	LowBatteryWarning = 'LOW_BATTERY_WARNING',
	IncompatibleAPXWithMXWWarning = 'INCOMPATIBLE_APX_MXW_WARNING',
	IncompatibleNDXWithMXWWarning = 'INCOMPATIBLE_NDX_MXW_WARNING',
	LinkedTransmitterError = 'LINKED_TRANSMITTER_ERROR',
	LinkedTransmitterWarning = 'LINKED_TRANSMITTER_WARNING',
	DockedTransmitterError = 'DOCKED_TRANSMITTER_ERROR',
	DockedTransmitterWarning = 'DOCKED_TRANSMITTER_WARNING',
	RFScanInProgress = 'RF_SCAN_IN_PROGRESS'
}

export enum ErrorLevel {
	Error = 'ERROR',
	Warning = 'WARNING',
	Info = 'INFO',
	RFScanning = 'RFSCANNING',
	None = 'NONE'
}
